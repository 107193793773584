// FinalizarCompra.js

import React, { useState, useEffect } from "react";
import { Trash2, Check } from "lucide-react";
import clsx from "clsx";
import axiosInstance from "./axiosConfig";
import { toast } from "react-toastify";

// Componente Button
const Button = React.forwardRef(
    ({ className, variant = "default", size = "default", ...props }, ref) => {
        const classes = clsx(
            "inline-flex items-center justify-center rounded-3xl text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
            {
                "bg-primary text-primary-foreground hover:bg-primary/90":
                    variant === "default",
                "bg-destructive text-destructive-foreground hover:bg-destructive/90":
                    variant === "destructive",
                "border border-input bg-background hover:bg-accent hover:text-accent-foreground":
                    variant === "outline",
                "bg-secondary text-secondary-foreground hover:bg-secondary/80":
                    variant === "secondary",
                "hover:bg-accent hover:text-accent-foreground":
                    variant === "ghost",
                "text-primary underline-offset-4 hover:underline":
                    variant === "link",
            },
            {
                "h-10 px-4 py-2": size === "default",
                "h-9 rounded-3xl px-3": size === "sm",
                "h-11 rounded-3xl px-8": size === "lg",
                "h-10 w-10": size === "icon",
            },
            className,
        );
        return <button className={classes} ref={ref} {...props} />;
    },
);
Button.displayName = "Button";

// Componente Checkbox personalizado
const Checkbox = ({ checked, onChange, disabled }) => {
    return (
        <div
            onClick={!disabled ? onChange : undefined}
            className={clsx(
                "w-5 h-5 border-2 rounded-3xl cursor-pointer flex items-center justify-center transition-colors duration-200",
                checked
                    ? "bg-primary border-primary"
                    : "bg-background border-input",
                disabled && "cursor-not-allowed opacity-50",
            )}
        >
            {checked && <Check className="w-4 h-4 text-primary-foreground" />}
        </div>
    );
};

const FinalizarCompra = ({
    cart,
    setCart,
    onClose,
    username,
    userBalance,
    setUserBalance,
    updateCartOnServer,
    removeCard,
    setCurrentPage, // Nova prop adicionada
}) => {
    const [selectedItems, setSelectedItems] = useState({});
    const [cartItems, setCartItems] = useState([]);
    const [itemStatuses, setItemStatuses] = useState({});
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        // Função para buscar detalhes dos cartões
        const fetchCartItems = async () => {
            try {
                if (cart.length === 0) {
                    setCartItems([]);
                    return;
                }
                const response = await axiosInstance.post("/get-cards-by-ids", {
                    ids: cart,
                });
                setCartItems(response.data.cards);
            } catch (error) {
                console.error("Erro ao buscar detalhes dos cartões:", error);
                toast.error("Erro ao carregar detalhes dos cartões.");
            }
        };

        fetchCartItems();
    }, [cart]);

    const handleSelectItem = (id) => {
        setSelectedItems((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    const handleSelectAll = () => {
        if (areAllSelected()) {
            // Desmarcar todos
            setSelectedItems({});
        } else {
            // Selecionar todos que não estão com status 'failed' ou 'processing'
            const newSelected = {};
            cartItems.forEach((item) => {
                if (!itemStatuses[item._id]) {
                    newSelected[item._id] = true;
                }
            });
            setSelectedItems(newSelected);
        }
    };

    const areAllSelected = () => {
        const selectableItems = cartItems.filter(
            (item) => !itemStatuses[item._id],
        );
        return (
            selectableItems.length > 0 &&
            selectableItems.every((item) => selectedItems[item._id])
        );
    };

    // Calcular o total da compra (itens selecionados)
    const totalPrice = cartItems.reduce((total, item) => {
        if (selectedItems[item._id] && !itemStatuses[item._id]?.status) {
            const price = parseFloat(item.price);
            return total + (isNaN(price) ? 0 : price);
        }
        return total;
    }, 0);

    // Calcular o saldo após compra somente se houver itens selecionados
    const hasSelectedItems = Object.values(selectedItems).some(Boolean);
    const finalBalance = hasSelectedItems
        ? userBalance - totalPrice
        : userBalance;

    const handleFinalize = async () => {
        const selectedIds = cartItems
            .filter(
                (item) => selectedItems[item._id] && !itemStatuses[item._id],
            )
            .map((item) => item._id);

        if (selectedIds.length === 0) {
            toast.warn(
                "Por favor, selecione pelo menos um item para finalizar a compra.",
            );
            return;
        }

        if (totalPrice > userBalance) {
            toast.error("Saldo insuficiente para realizar a compra.");
            return;
        }

        setIsProcessing(true);
        let hasSuccessfulPurchase = false;

        for (const cardId of selectedIds) {
            try {
                // Atualizar status para 'processing'
                setItemStatuses((prev) => ({
                    ...prev,
                    [cardId]: { status: "processing" },
                }));

                // Fazer a solicitação de compra para este cartão
                const response = await axiosInstance.post("/purchase", {
                    cards: [cardId],
                });

                if (response.status === 200) {
                    const { new_balance, purchased_cards, failed_cards } =
                        response.data;

                    if (purchased_cards.includes(cardId)) {
                        // Atualizar o saldo do usuário
                        setUserBalance(new_balance);

                        // Atualizar status para 'success'
                        setItemStatuses((prev) => ({
                            ...prev,
                            [cardId]: { status: "success" },
                        }));

                        // Remover o cartão da lista de cartões
                        removeCard(cardId);

                        toast.success(
                            `Compra do cartão ${cardId} realizada com sucesso!`,
                        );
                        hasSuccessfulPurchase = true;
                    } else if (
                        failed_cards.some((fc) => fc.card_id === cardId)
                    ) {
                        const failedCard = failed_cards.find(
                            (fc) => fc.card_id === cardId,
                        );
                        // Atualizar status para 'failed' com a mensagem de erro
                        setItemStatuses((prev) => ({
                            ...prev,
                            [cardId]: {
                                status: "failed",
                                error: failedCard.error,
                            },
                        }));

                        toast.error(
                            `Erro ao comprar o cartão: ${failedCard.error}`,
                        );

                        // Remover o cartão da lista de cartões
                        removeCard(cardId);
                    }
                } else {
                    const { error } = response.data;
                    setItemStatuses((prev) => ({
                        ...prev,
                        [cardId]: {
                            status: "failed",
                            error: error || "Erro desconhecido",
                        },
                    }));
                    toast.error(
                        `Erro ao finalizar compra do cartão ${cardId}: ${
                            error || "Erro desconhecido"
                        }`,
                    );

                    // Remover o cartão da lista de cartões
                    removeCard(cardId);
                }
            } catch (error) {
                console.error("Erro ao finalizar compra:", error);
                setItemStatuses((prev) => ({
                    ...prev,
                    [cardId]: { status: "failed", error: "Erro na requisição" },
                }));
                toast.error(`Erro ao finalizar compra do cartão ${cardId}.`);

                // Remover o cartão da lista de cartões
                removeCard(cardId);
            }
        }

        setIsProcessing(false);

        // Se houve pelo menos uma compra bem-sucedida, redirecione após um pequeno delay
        if (hasSuccessfulPurchase) {
            setTimeout(() => {
                setCurrentPage("minhas-compras");
            }, 2000); // Delay de 2 segundos
        }
    };

    const handleRemoveItem = (id) => {
        // Remover o cartão do carrinho e da lista de cartões
        setCart((prevCart) => prevCart.filter((itemId) => itemId !== id));
        setCartItems((prevItems) =>
            prevItems.filter((item) => item._id !== id),
        );
        setSelectedItems((prev) => {
            const newSelected = { ...prev };
            delete newSelected[id];
            return newSelected;
        });
        // Atualize o carrinho no servidor
        updateCartOnServer(cart.filter((itemId) => itemId !== id));

        // Remover o cartão da lista de cartões
        removeCard(id);

        toast.info("Item removido do carrinho.");
    };

    return (
        <div className="w-full max-w-4xl mx-auto">
            <h2 className="text-2xl font-bold mb-4">Finalizar Compra</h2>

            <p className="mb-2">
                Saldo atual:{" "}
                <span className="font-semibold">
                    R${" "}
                    {userBalance !== undefined
                        ? userBalance.toFixed(2)
                        : "0.00"}
                </span>
            </p>

            {cartItems.length === 0 ? (
                <p className="text-center">Seu carrinho está vazio.</p>
            ) : (
                <div className="space-y-4">
                    {/* Checkbox "Selecionar Todos" */}
                    <div className="flex items-center mb-2">
                        <Checkbox
                            checked={areAllSelected()}
                            onChange={handleSelectAll}
                            disabled={cartItems.length === 0}
                        />
                        <label className="ml-2 text-sm font-medium">
                            Selecionar Todos
                        </label>
                    </div>

                    {cartItems.map((item) => (
                        <div
                            key={item._id}
                            className="flex items-center justify-between p-4 border border-border rounded-3xl w-full"
                        >
                            <div className="flex items-center space-x-4 flex-grow">
                                <Checkbox
                                    checked={!!selectedItems[item._id]}
                                    onChange={() => handleSelectItem(item._id)}
                                    disabled={!!itemStatuses[item._id]}
                                />
                                <div className="min-w-0 flex-shrink">
                                    <p className="font-semibold truncate">
                                        {item.nome}
                                    </p>
                                    <p className="text-sm text-muted-foreground">
                                        BIN: {item.bin} | Validade: {item.month}
                                        /{item.year}
                                    </p>
                                    <p className="text-sm text-muted-foreground">
                                        Preço: R${" "}
                                        {item.price !== undefined &&
                                        !isNaN(item.price)
                                            ? parseFloat(item.price).toFixed(2)
                                            : "0.00"}
                                    </p>
                                    {/* Status messages */}
                                    {itemStatuses[item._id]?.status ===
                                        "processing" && (
                                        <p className="text-sm text-blue-600">
                                            Processando...
                                        </p>
                                    )}
                                    {itemStatuses[item._id]?.status ===
                                        "success" && (
                                        <p className="text-sm text-green-600">
                                            Compra realizada com sucesso
                                        </p>
                                    )}
                                    {itemStatuses[item._id]?.status ===
                                        "failed" && (
                                        <p className="text-sm text-red-600">
                                            Erro: {itemStatuses[item._id].error}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <button
                                onClick={() => handleRemoveItem(item._id)}
                                className="flex-none ml-4 text-destructive hover:text-destructive-foreground"
                                aria-label="Remover item"
                                disabled={isProcessing}
                            >
                                <Trash2 className="h-5 w-5" />
                            </button>
                        </div>
                    ))}
                </div>
            )}

            {/* Exibir o total dos itens selecionados e saldo após compra */}
            <div className="mt-4">
                <p className="text-lg font-semibold">
                    Total dos itens selecionados: R$ {totalPrice.toFixed(2)}
                </p>
                {/* Exibir o saldo após a compra de forma condicional */}
                <p className="text-sm text-muted-foreground">
                    Saldo após compra: R$ {finalBalance.toFixed(2)}
                </p>
            </div>

            <div className="mt-6 flex justify-end space-x-4">
                <Button
                    variant="default"
                    onClick={handleFinalize}
                    disabled={
                        Object.values(selectedItems).filter(Boolean).length ===
                            0 || isProcessing
                    }
                >
                    Finalizar Compra
                </Button>
            </div>
        </div>
    );
};

export default FinalizarCompra;
