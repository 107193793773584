import axios from "axios";

const axiosInstance = axios.create({
    baseURL: "https://api.astrashopbot.cc/api",
    headers: {
        "Content-Type": "application/json",
    },
    withCredentials: true,
});

export const setAuthToken = (token) => {
    if (token) {
        axiosInstance.defaults.headers.common["Authorization"] =
            `Bearer ${token}`;
        localStorage.setItem("token", token);
    } else {
        delete axiosInstance.defaults.headers.common["Authorization"];
        localStorage.removeItem("token");
    }
};

export const setCsrfToken = (token) => {
    if (token) {
        axiosInstance.defaults.headers.common["X-CSRFToken"] = token;
    } else {
        delete axiosInstance.defaults.headers.common["X-CSRFToken"];
    }
};

const isTokenValid = (token) => {
    if (!token) return false;
    try {
        const payload = JSON.parse(atob(token.split(".")[1]));
        const expiry = payload.exp * 1000;
        return Date.now() < expiry;
    } catch (e) {
        return false;
    }
};

const initializeAuth = () => {
    const token = localStorage.getItem("token");
    if (token && isTokenValid(token)) {
        setAuthToken(token);
        return true;
    } else {
        clearSessionData();
        return false;
    }
};

const clearSessionData = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("cart");
    localStorage.removeItem("userBalance");
    localStorage.removeItem("purchaseMode");
    delete axiosInstance.defaults.headers.common["Authorization"];
    delete axiosInstance.defaults.headers.common["X-CSRFToken"];
};

const handleLogout = () => {
    clearSessionData();
    window.location.href = "/";
};

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response?.status === 401) {
            if (
                !error.config.url.includes("/login") &&
                !error.config.url.includes("/register")
            ) {
                clearSessionData();
                window.location.href = "/";
            }
        }
        return Promise.reject(error);
    },
);

initializeAuth();

export default axiosInstance;
