// HashGuard.js
import React from "react";
import { createBLAKE3 } from "hash-wasm";
import {
    Upload,
    File,
    Loader2,
    ArrowRight,
    CheckCircle2,
    Globe,
    Key,
    Copy,
    Check,
    Sparkles,
    BookOpen,
    Hash,
} from "lucide-react";
import { toast } from "react-toastify";
import UserProfile from "./UserProfile"; // Ajuste o caminho conforme necessário

const cn = (...classes) => classes.filter(Boolean).join(" ");

const Btn01 = ({ className, children = "Continue", ...props }) => {
    return (
        <button
            className={cn(
                "relative h-11 px-6",
                "bg-zinc-900 dark:bg-zinc-50",
                "text-zinc-50 dark:text-zinc-900",
                "hover:bg-zinc-800 dark:hover:bg-zinc-100",
                "rounded-xl",
                "transition-all duration-300",
                "overflow-hidden",
                "group",
                className,
            )}
            {...props}
        >
            <div
                className={cn(
                    "flex items-center gap-2",
                    "transition-all duration-300",
                )}
            >
                <span>{children}</span>
                <ArrowRight
                    className={cn(
                        "w-4 h-4",
                        "transition-transform duration-300",
                        "group-hover:translate-x-1",
                    )}
                />
            </div>
        </button>
    );
};

const BASE_URL = "https://api.astrashopbot.cc";

const cnButton = (...classes) => classes.filter(Boolean).join(" ");

function useCopyToClipboard({ timeout = 1000 } = {}) {
    const [isCopied, setIsCopied] = React.useState(false);

    const copyToClipboard = (value) => {
        if (typeof window === "undefined" || !navigator.clipboard?.writeText)
            return;
        navigator.clipboard.writeText(value).then(() => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), timeout);
        });
    };

    return { isCopied, copyToClipboard };
}

const Button = ({ children, className, onClick, disabled, loading }) => {
    return (
        <button
            onClick={onClick}
            className={cnButton(
                "relative h-11 px-6",
                "bg-white dark:bg-zinc-900/20",
                "text-zinc-900 dark:text-zinc-100",
                "border border-zinc-200 dark:border-zinc-800",
                "shadow-sm",
                "hover:shadow-md hover:-translate-y-0.5",
                "hover:bg-zinc-50 dark:hover:bg-zinc-900",
                "active:translate-y-0",
                "transition-all duration-200",
                "after:absolute after:inset-0",
                "after:rounded-[inherit] after:opacity-0",
                "after:border after:border-zinc-900/10 dark:after:border-white/10",
                "after:transition-opacity after:duration-300",
                "hover:after:opacity-100",
                disabled && "opacity-50 cursor-not-allowed",
                className,
            )}
            disabled={disabled}
        >
            {loading && (
                <Loader2 className="w-4 h-4 animate-spin mr-2 inline-block" />
            )}
            {children}
        </button>
    );
};

const SmallCopyButton = ({ textToCopy, successDuration = 1000, className }) => {
    const { isCopied, copyToClipboard } = useCopyToClipboard({
        timeout: successDuration,
    });

    const handleCopy = () => {
        if (!isCopied) copyToClipboard(textToCopy);
    };

    return (
        <button
            onClick={handleCopy}
            className={cnButton(
                "group",
                "p-1 text-xs h-auto w-auto",
                "bg-transparent",
                "text-emerald-600 dark:text-emerald-300",
                className,
            )}
            aria-label="Copiar para a área de transferência"
        >
            {isCopied ? (
                <Check className="w-4 h-4 text-emerald-500" />
            ) : (
                <Copy className="w-4 h-4 transition-transform duration-200 group-hover:scale-110" />
            )}
        </button>
    );
};

const Notification = ({ message }) => (
    <div className="w-full max-w-sm mx-auto">
        <div className="relative overflow-hidden rounded-lg border border-emerald-300 bg-emerald-100 dark:bg-emerald-950/20 dark:border-emerald-800/30 p-4 shadow-sm">
            <div className="flex items-center gap-3">
                <div className="rounded-full bg-emerald-200 dark:bg-emerald-900/50 p-1">
                    <CheckCircle2 className="h-4 w-4 text-emerald-700 dark:text-emerald-400" />
                </div>
                <p className="text-sm font-medium text-emerald-900 dark:text-emerald-200">
                    {message}
                </p>
            </div>
        </div>
    </div>
);

class HashGuard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeSection: "register",
            vendorToken: "",
            endpoint: "",
            showSuccess: false,
            registrationLoading: false,
            isAuthenticated: false,
            loadingAuth: true,
            fileContent: "",
            hashesOutput: [],
            selectedFile: null,
            isDragging: false,
            loading: false,
            sending: false,
            identifiedCards: 0,
            unidentifiedCards: 0,
            successfulHashes: 0,
            errorHashes: 0,
            stats: null,
            profileImage: "",
        };

        this.intervalId = null;

        this.handleVendorAuthChange = this.handleVendorAuthChange.bind(this);
        this.fetchStats = this.fetchStats.bind(this);
        this.handleRegister = this.handleRegister.bind(this);
        this.processFile = this.processFile.bind(this);
        this.handleClearFile = this.handleClearFile.bind(this);
        this.handleSendHashes = this.handleSendHashes.bind(this);
        this.generateHashes = this.generateHashes.bind(this);
        this.verifyToken = this.verifyToken.bind(this);
    }

    async componentDidMount() {
        await this.verifyToken();
        this.setState({ loadingAuth: false });
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevState.vendorToken && this.state.vendorToken) {
            this.fetchStats();
            this.intervalId = setInterval(this.fetchStats, 5000);
        }

        if (prevState.fileContent !== this.state.fileContent) {
            this.generateHashes();
        }
    }

    componentWillUnmount() {
        if (this.intervalId) clearInterval(this.intervalId);
    }

    async verifyToken() {
        const token = localStorage.getItem("token");
        if (token) {
            try {
                const response = await fetch(`${BASE_URL}/api/user-info`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    this.setState({ isAuthenticated: true });

                    if (data.vendor_data) {
                        this.setState({
                            vendorToken: data.vendor_data.token,
                            endpoint: data.vendor_data.endpoint_url,
                        });
                    }

                    if (data.user_info && data.user_info.profile_image) {
                        this.setState({
                            profileImage: data.user_info.profile_image,
                        });
                    }
                } else {
                    this.setState({ isAuthenticated: false });
                    localStorage.removeItem("token");
                    console.warn("Token inválido ou expirado.");
                }
            } catch (error) {
                console.error("Erro ao autenticar:", error);
                this.setState({ isAuthenticated: false });
                localStorage.removeItem("token");
            }
        } else {
            this.setState({ isAuthenticated: false });
        }
    }

    handleVendorAuthChange(status) {
        if (!status) {
            this.setState({
                vendorToken: "",
                endpoint: "",
            });
            toast.warn(
                "Seu token de vendor foi desativado. Por favor, registre-se novamente.",
            );
            this.setState({ activeSection: "register" });
        }
    }

    async fetchStats() {
        const { isAuthenticated, vendorToken } = this.state;
        if (!isAuthenticated || !vendorToken) return;
        try {
            const response = await fetch(`${BASE_URL}/api/dev/stats`, {
                headers: {
                    Authorization: `Bearer ${vendorToken}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                this.setState({ stats: data });
            } else {
                const errorData = await response.json();
                if (errorData.error_code === "INVALID_TOKEN") {
                    this.handleVendorAuthChange(false);
                } else {
                    console.error(
                        "Erro ao buscar estatísticas:",
                        errorData.error,
                    );
                    toast.error("Erro ao buscar estatísticas");
                }
            }
        } catch (error) {
            console.error("Erro ao buscar estatísticas:", error);
            toast.error("Erro ao buscar estatísticas");
        }
    }

    async handleRegister() {
        const { endpoint, isAuthenticated } = this.state;

        if (!endpoint) {
            toast.error("URL do Endpoint não fornecida");
            return;
        }

        if (!isAuthenticated) {
            toast.error(
                "Você precisa estar autenticado para realizar esta ação",
            );
            return;
        }

        this.setState({ registrationLoading: true });
        try {
            const jwtToken = localStorage.getItem("token");

            const response = await fetch(
                `${BASE_URL}/api/dev/register_vendor`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${jwtToken}`,
                    },
                    body: JSON.stringify({ endpoint_url: endpoint }),
                    credentials: "include",
                },
            );

            if (response.ok) {
                const data = await response.json();
                if (data.token && data.endpoint_url && data.expires_at) {
                    this.setState({
                        vendorToken: data.token,
                        endpoint: data.endpoint_url,
                        showSuccess: true,
                    });
                    setTimeout(
                        () => this.setState({ showSuccess: false }),
                        3000,
                    );
                    this.setState({ activeSection: "docs" });
                    toast.success("Registro realizado com sucesso!");
                    localStorage.setItem("vendorToken", data.token);
                    this.fetchStats();
                } else {
                    toast.error("Dados de registro incompletos recebidos.");
                    console.error("Resposta de registro incompleta:", data);
                }
            } else {
                const data = await response.json();
                toast.error(`Erro ao registrar: ${data.error}`);
                console.error("Erro ao registrar token do vendor:", data.error);
            }
        } catch (error) {
            console.error("Erro ao registrar:", error);
            toast.error("Erro ao registrar novo token");
        } finally {
            this.setState({ registrationLoading: false });
        }
    }

    handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    handleDragIn = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ isDragging: true });
    };

    handleDragOut = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ isDragging: false });
    };

    handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ isDragging: false });
        const files = e.dataTransfer.files;
        if (files && files.length > 0) this.processFile(files[0]);
    };

    async processFile(file) {
        if (!file) return;
        this.setState({ selectedFile: file, loading: true });
        try {
            const text = await file.text();
            this.setState({ fileContent: text });
            console.log("Conteúdo do arquivo carregado:", text);
        } catch (e) {
            console.error("Erro ao ler o arquivo:", e);
            toast.error("Erro ao ler o arquivo");
        }
        this.setState({ loading: false });
    }

    handleClearFile() {
        this.setState({
            selectedFile: null,
            fileContent: "",
            hashesOutput: [],
            identifiedCards: 0,
            unidentifiedCards: 0,
            successfulHashes: 0,
            errorHashes: 0,
        });
        console.log("Arquivo e dados relacionados foram limpos.");
    }

    async generateHashes() {
        const { fileContent } = this.state;
        if (fileContent.trim()) {
            this.setState({ loading: true });
            try {
                const lines = fileContent.trim().split("\n");
                const hashes = [];
                let identified = 0;
                let unidentified = 0;

                for (const line of lines) {
                    const trimmedLine = line.trim();
                    if (trimmedLine) {
                        const regex =
                            /(\d{14,16})\D*(\d{1,2})\D*(\d{2,4})\D*(\d{3,4})/;
                        const match = trimmedLine.match(regex);
                        if (match) {
                            const cardNumber = match[1];
                            const month = match[2];
                            const year = match[3];
                            const cvv = match[4];
                            const bin = cardNumber.slice(0, 6);
                            const unifiedData = `${cardNumber}|${month}|${year}|${cvv}`;

                            const blake3Hasher = await createBLAKE3(512);
                            blake3Hasher.init();
                            blake3Hasher.update(unifiedData);
                            const resultHash = blake3Hasher.digest("hex");
                            hashes.push({ hash: resultHash, bin, month, year });

                            identified++;
                            console.log(
                                `Hash gerada para: ${unifiedData} -> ${resultHash} | BIN: ${bin}`,
                            );
                        } else {
                            unidentified++;
                            console.warn(
                                `Linha não identificada: ${trimmedLine}`,
                            );
                        }
                    }
                }

                this.setState({
                    hashesOutput: hashes,
                    identifiedCards: identified,
                    unidentifiedCards: unidentified,
                });

                console.log(
                    `Hashes geradas: ${hashes.length}, Identificados: ${identified}, Não Identificados: ${unidentified}`,
                );
            } catch (e) {
                console.error("Erro ao gerar hashes:", e);
                toast.error("Erro ao gerar hashes");
            }
            this.setState({ loading: false });
        } else {
            this.setState({
                hashesOutput: [],
                identifiedCards: 0,
                unidentifiedCards: 0,
            });
            console.warn("Conteúdo do arquivo está vazio após trim.");
        }
    }

    async handleSendHashes() {
        const {
            hashesOutput,
            vendorToken,
            selectedFile,
            fileContent,
            identifiedCards,
            unidentifiedCards,
        } = this.state;

        if (!hashesOutput.length) {
            toast.error("Nenhuma hash para enviar");
            return;
        }
        if (!vendorToken) {
            toast.error("Token do vendor não encontrado");
            return;
        }
        if (!selectedFile) {
            toast.error("Nenhum arquivo selecionado");
            return;
        }
        this.setState({ sending: true });
        try {
            const hashes = hashesOutput;
            const filename = selectedFile.name;
            const line_count = fileContent.trim().split("\n").length;

            const response = await fetch(`${BASE_URL}/api/dev/add_hash`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${vendorToken}`,
                },
                body: JSON.stringify({
                    hashes,
                    filename,
                    line_count,
                    identified_cards: identifiedCards,
                    unidentified_cards: unidentifiedCards,
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                if (errorData.error_code === "INVALID_TOKEN") {
                    this.handleVendorAuthChange(false);
                    this.setState({ sending: false });
                    return;
                }
                toast.error(`Erro ao enviar hashes: ${errorData.error}`);
                console.error("Erro ao enviar hashes:", errorData.error);
                this.setState({ sending: false });
                return;
            }

            const data = await response.json();
            const successCount = data.results.filter(
                (r) => r.status === "success",
            ).length;
            const errorCount = data.results.length - successCount;

            this.setState({
                successfulHashes: successCount,
                errorHashes: errorCount,
                showSuccess: true,
            });
            toast.success(`${successCount} hashes enviadas com sucesso!`);
            console.log(`${successCount} hashes enviadas com sucesso!`);
            setTimeout(() => this.setState({ showSuccess: false }), 3000);

            this.fetchStats();
        } catch (error) {
            console.error("Erro ao enviar hashes:", error);
            toast.error("Erro ao enviar hashes");
            this.setState({ errorHashes: hashesOutput.length });
        }
        this.setState({ sending: false });
    }

    render() {
        const {
            activeSection,
            vendorToken,
            endpoint,
            showSuccess,
            registrationLoading,
            isAuthenticated,
            loadingAuth,
            fileContent,
            hashesOutput,
            selectedFile,
            isDragging,
            loading,
            sending,
            identifiedCards,
            unidentifiedCards,
            successfulHashes,
            errorHashes,
            stats,
            profileImage,
        } = this.state;

        if (loadingAuth) {
            return (
                <div className="flex justify-center items-center h-screen">
                    <Loader2 className="w-10 h-10 animate-spin text-blue-500" />
                </div>
            );
        }

        return (
            <div className="min-h-screen p-8">
                <div className="max-w-6xl mx-auto space-y-8">
                    <div className="flex items-center justify-center">
                        <div className="relative px-4 py-2 overflow-hidden">
                            <h1 className="text-4xl sm:text-3xl font-bold shimmer-text">
                                HashGuard
                            </h1>
                        </div>
                        <style>{`.shimmer-text {
                          --shimmer-color-start: #334155;
                          --shimmer-color-mid: #94a3b8;
                          background: linear-gradient(
                            90deg,
                            var(--shimmer-color-start) 0%,
                            var(--shimmer-color-start) 40%,
                            var(--shimmer-color-mid) 50%,
                            var(--shimmer-color-start) 60%,
                            var(--shimmer-color-start) 100%
                          );
                          background-size: 200% 100%;
                          -webkit-background-clip: text;
                          background-clip: text;
                          color: transparent;
                          animation: shimmer 2.5s infinite linear;
                        }

                        @media (prefers-color-scheme: dark) {
                          .shimmer-text {
                            --shimmer-color-start: #f1f5f9;
                            --shimmer-color-mid: #9333EA;
                          }
                        }

                        @keyframes shimmer {
                          0% { background-position: 100% 0; }
                          100% { background-position: -100% 0; }
                        }`}</style>
                    </div>

                    <nav className="flex gap-4 mb-8 justify-center">
                        {["register", "docs", "convert"].map((section) => (
                            <Button
                                key={section}
                                className={cnButton(
                                    "flex items-center gap-2",
                                    activeSection === section
                                        ? "bg-blue-500 text-white dark:bg-blue-500 dark:text-white"
                                        : "bg-transparent text-zinc-600 dark:text-zinc-400",
                                )}
                                onClick={() =>
                                    this.setState({ activeSection: section })
                                }
                            >
                                {section === "register" && (
                                    <Key className="w-4 h-4" />
                                )}
                                {section === "docs" && (
                                    <BookOpen className="w-4 h-4" />
                                )}
                                {section === "convert" && (
                                    <Hash className="w-4 h-4" />
                                )}
                                {section === "register" && "Registrar Token"}
                                {section === "docs" && "Documentação"}
                                {section === "convert" && "Converter Cartões"}
                            </Button>
                        ))}
                    </nav>

                    {showSuccess && (
                        <Notification message="Operação realizada com sucesso!" />
                    )}

                    {activeSection === "register" && (
                        <div className="relative w-full mx-auto">
                            {vendorToken ? (
                                <div className="space-y-6">
                                    <UserProfile
                                        vendorToken={vendorToken}
                                        stats={stats}
                                        profileImage={profileImage}
                                        onVendorAuthChange={
                                            this.handleVendorAuthChange
                                        }
                                    />

                                    <div className="max-w-3xl mx-auto">
                                        <div className="p-6 rounded-2xl border border-zinc-200 dark:border-zinc-800 bg-white dark:bg-zinc-900">
                                            <div className="space-y-4">
                                                <div className="flex items-center gap-2">
                                                    <Key className="w-5 h-5 text-purple-500" />
                                                    <h3 className="text-xl font-semibold">
                                                        Seu Token de API
                                                    </h3>
                                                </div>
                                                <div className="p-4 rounded-lg bg-zinc-50 dark:bg-zinc-800 font-mono text-sm flex items-center justify-between">
                                                    <div className="break-all">
                                                        {vendorToken}
                                                    </div>
                                                    <SmallCopyButton
                                                        textToCopy={vendorToken}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="max-w-lg mx-auto">
                                    <div className="relative overflow-hidden rounded-2xl border border-zinc-200 dark:border-zinc-800 bg-white dark:bg-zinc-900 shadow-lg">
                                        <div className="p-7 space-y-4">
                                            <div className="inline-flex items-center gap-2 px-3 py-1.5 rounded-lg bg-zinc-100 dark:bg-zinc-800 w-fit">
                                                <Sparkles className="w-4 h-4 text-zinc-600 dark:text-zinc-400" />
                                                <span className="text-sm font-medium text-zinc-600 dark:text-zinc-400">
                                                    Configuração do Vendor
                                                </span>
                                            </div>
                                            <div>
                                                <h3 className="text-xl font-semibold text-zinc-900 dark:text-zinc-50">
                                                    Registrar Token de API
                                                </h3>
                                                <p className="mt-2 text-sm text-zinc-600 dark:text-zinc-400">
                                                    Registre sua URL de endpoint
                                                    para receber um token de API
                                                </p>
                                            </div>
                                        </div>

                                        <div className="p-7 space-y-5 border-t border-zinc-100 dark:border-zinc-800">
                                            <div className="space-y-2.5">
                                                <label className="text-sm font-medium text-zinc-700 dark:text-zinc-300">
                                                    URL do Endpoint
                                                </label>
                                                <div className="relative">
                                                    <div className="absolute left-3 top-1/2 -translate-y-1/2 flex items-center">
                                                        <Globe className="w-4 h-4 text-zinc-400 dark:text-zinc-500" />
                                                    </div>
                                                    <input
                                                        value={endpoint}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                endpoint:
                                                                    e.target
                                                                        .value,
                                                            })
                                                        }
                                                        placeholder="https://seu-endpoint-api.com"
                                                        className="w-full pl-10 h-11 rounded-lg bg-zinc-50 dark:bg-zinc-800/50 border border-zinc-200 dark:border-zinc-800 focus:border-zinc-400 dark:focus:border-zinc-700 focus-visible:ring-1 focus-visible:ring-zinc-400 dark:focus-visible:ring-zinc-700 focus:outline-none text-zinc-900 dark:text-zinc-100 placeholder:text-zinc-500 dark:placeholder:text-zinc-600"
                                                        disabled={
                                                            !isAuthenticated
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="p-7 flex justify-end border-t border-zinc-100 dark:border-zinc-800">
                                            <Button
                                                onClick={this.handleRegister}
                                                className="flex items-center gap-2"
                                                disabled={
                                                    !endpoint ||
                                                    registrationLoading ||
                                                    !isAuthenticated
                                                }
                                                loading={registrationLoading}
                                            >
                                                <Sparkles className="w-4 h-4" />
                                                Gerar Token
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}

                    {activeSection === "docs" && (
                        <div className="space-y-8">
                            {vendorToken && (
                                <div className="p-6 rounded-2xl border border-zinc-200 dark:border-zinc-800 bg-white dark:bg-zinc-900">
                                    <div className="space-y-4">
                                        <div className="flex items-center gap-2">
                                            <Key className="w-5 h-5 text-purple-500" />
                                            <h3 className="text-xl font-semibold">
                                                Seu Token de API
                                            </h3>
                                        </div>
                                        <div className="p-4 rounded-lg bg-zinc-50 dark:bg-zinc-800 font-mono text-sm flex items-center justify-between">
                                            <div className="break-all">
                                                {vendorToken}
                                            </div>
                                            <SmallCopyButton
                                                textToCopy={vendorToken}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="space-y-6">
                                {[
                                    {
                                        title: "Add Hash",
                                        endpoint: "/api/dev/add_hash",
                                        method: "POST",
                                        description:
                                            "Adicione novas hashes de cartão ao marketplace",
                                        body: {
                                            hashes: [
                                                {
                                                    hash: "blake3_hash_128_chars",
                                                    bin: "123456",
                                                    month: "12",
                                                    year: "24",
                                                },
                                                {
                                                    hash: "blake3_hash_128_chars",
                                                    bin: "654321",
                                                    month: "01",
                                                    year: "26",
                                                },
                                            ],
                                            filename: "cards.txt",
                                            line_count: 100,
                                            identified_cards: 95,
                                            unidentified_cards: 5,
                                        },
                                        responseExample: {
                                            results: [
                                                {
                                                    hash: "blake3_hash_128_chars",
                                                    status: "success",
                                                    id: "inserted_hash_id",
                                                },
                                                {
                                                    hash: "blake3_hash_128_chars",
                                                    status: "error",
                                                    message:
                                                        "Hash already exists",
                                                },
                                            ],
                                            file_id: "inserted_file_id",
                                        },
                                        authRequired: true,
                                    },
                                    {
                                        title: "Verify Hash",
                                        endpoint: "/api/dev/verify_hash",
                                        method: "POST",
                                        description:
                                            "Verifique se uma hash existe e obtenha seu status",
                                        body: {
                                            hash: "blake3_hash_128_chars",
                                        },
                                        responseExample: {
                                            exists: true,
                                            hash: "blake3_hash_128_chars",
                                            status: "active",
                                            created_at:
                                                "2024-11-18T12:00:00.000Z",
                                            vendor_name: "Vendor Name",
                                            endpoint_url:
                                                "https://vendor-endpoint.com",
                                        },
                                        authRequired: true,
                                    },
                                    {
                                        title: "Get Stats",
                                        endpoint: "/api/dev/stats",
                                        method: "GET",
                                        description:
                                            "Obtenha estatísticas sobre suas hashes",
                                        responseExample: {
                                            total_hashes: 100,
                                            active_hashes: 80,
                                            inactive_hashes: 20,
                                            new_hashes_24h: 5,
                                            uploaded_files: [
                                                {
                                                    file_id: "file_id_1",
                                                    filename: "cards1.txt",
                                                    line_count: 100,
                                                    identified_cards: 95,
                                                    unidentified_cards: 5,
                                                    timestamp:
                                                        "2024-11-18T12:00:00.000Z",
                                                },
                                                {
                                                    file_id: "file_id_2",
                                                    filename: "cards2.txt",
                                                    line_count: 200,
                                                    identified_cards: 190,
                                                    unidentified_cards: 10,
                                                    timestamp:
                                                        "2024-11-19T14:30:00.000Z",
                                                },
                                            ],
                                            timestamp:
                                                "2024-11-27T15:45:30.123456Z",
                                        },
                                        authRequired: true,
                                    },
                                ].map((route) => (
                                    <div
                                        key={route.endpoint}
                                        className="p-6 rounded-2xl border border-zinc-200 dark:border-zinc-800 bg-white dark:bg-zinc-900"
                                    >
                                        <div className="space-y-4">
                                            <div className="flex items-center justify-between">
                                                <div className="flex items-center gap-2">
                                                    <Globe className="w-5 h-5 text-blue-500" />
                                                    <h3 className="text-lg font-semibold">
                                                        {route.title}
                                                    </h3>
                                                </div>
                                                <span
                                                    className={cnButton(
                                                        "px-3 py-1 rounded-full text-sm font-medium",
                                                        route.method === "GET"
                                                            ? "bg-green-100 dark:bg-green-900 text-green-700 dark:text-green-300"
                                                            : "bg-blue-100 dark:bg-blue-900 text-blue-700 dark:text-blue-300",
                                                    )}
                                                >
                                                    {route.method}
                                                </span>
                                            </div>

                                            <p className="text-sm text-zinc-600 dark:text-zinc-400">
                                                {route.description}
                                            </p>

                                            <div className="space-y-2">
                                                <div className="text-sm font-medium text-zinc-600 dark:text-zinc-400">
                                                    Endpoint
                                                </div>
                                                <div className="p-3 rounded-lg bg-zinc-50 dark:bg-zinc-800 font-mono text-sm">
                                                    {route.endpoint}
                                                </div>
                                            </div>

                                            {route.body && (
                                                <div className="space-y-2">
                                                    <div className="text-sm font-medium text-zinc-600 dark:text-zinc-400">
                                                        Request Body
                                                    </div>
                                                    <div className="p-3 rounded-lg bg-zinc-50 dark:bg-zinc-800 font-mono text-sm">
                                                        {JSON.stringify(
                                                            route.body,
                                                            null,
                                                            2,
                                                        )}
                                                    </div>
                                                </div>
                                            )}

                                            <div className="space-y-2">
                                                <div className="text-sm font-medium text-zinc-600 dark:text-zinc-400">
                                                    Exemplo de Requisição
                                                </div>
                                                <div className="relative p-3 rounded-lg bg-zinc-50 dark:bg-zinc-800 font-mono text-sm">
                                                    <SmallCopyButton
                                                        textToCopy={`curl -X ${route.method} ${BASE_URL}${route.endpoint} -H "Authorization: Bearer ${vendorToken}" -H "Content-Type: application/json" ${
                                                            route.body
                                                                ? `-d '${JSON.stringify(route.body)}'`
                                                                : ""
                                                        }`}
                                                        className="absolute top-2 right-2"
                                                    />
                                                    <div className="whitespace-pre-wrap overflow-x-auto">
                                                        {`curl -X ${route.method} ${BASE_URL}${route.endpoint} \\
              -H "Authorization: Bearer ${vendorToken || "your_token_here"}" \\
              -H "Content-Type: application/json" ${
                  route.body
                      ? `\\
              -d '${JSON.stringify(route.body)}'`
                      : ""
              }`}
                                                    </div>
                                                </div>
                                            </div>

                                            {route.responseExample && (
                                                <div className="space-y-2">
                                                    <div className="text-sm font-medium text-zinc-600 dark:text-zinc-400">
                                                        Exemplo de Resposta
                                                    </div>
                                                    <div className="p-3 rounded-lg bg-zinc-50 dark:bg-zinc-800 font-mono text-sm">
                                                        {JSON.stringify(
                                                            route.responseExample,
                                                            null,
                                                            2,
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {activeSection === "convert" && (
                        <div className="space-y-8">
                            <div className="p-6 rounded-2xl border border-zinc-200 dark:border-zinc-800 bg-white dark:bg-zinc-900">
                                <h2 className="text-xl font-semibold mb-4">
                                    Converter Cartões para Hashes
                                </h2>

                                {!selectedFile ? (
                                    <div
                                        className={cnButton(
                                            "border-2 border-dashed rounded-lg p-8 text-center transition-colors",
                                            isDragging
                                                ? "border-blue-500 bg-blue-500/10"
                                                : "border-zinc-200 dark:border-zinc-700",
                                            loading
                                                ? "opacity-50"
                                                : "hover:border-blue-500",
                                            "bg-zinc-50 dark:bg-zinc-800",
                                        )}
                                        onDragEnter={this.handleDragIn}
                                        onDragLeave={this.handleDragOut}
                                        onDragOver={this.handleDrag}
                                        onDrop={this.handleDrop}
                                    >
                                        <div className="flex flex-col items-center justify-center space-y-4">
                                            <Upload className="w-12 h-12 text-zinc-400 dark:text-zinc-500" />
                                            <div>
                                                <p className="text-lg font-medium">
                                                    Arraste e solte seu arquivo
                                                    aqui ou
                                                </p>
                                                <label className="cursor-pointer text-blue-500 hover:text-blue-400">
                                                    <span>
                                                        selecione um arquivo
                                                    </span>
                                                    <input
                                                        type="file"
                                                        className="hidden"
                                                        onChange={(e) =>
                                                            this.processFile(
                                                                e.target
                                                                    .files[0],
                                                            )
                                                        }
                                                        accept=".txt"
                                                    />
                                                </label>
                                            </div>
                                            <p className="text-sm text-zinc-500 dark:text-zinc-400">
                                                Apenas arquivos .txt são aceitos
                                            </p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="space-y-4">
                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center space-x-3">
                                                <File
                                                    size={24}
                                                    className="text-blue-500"
                                                />
                                                <div>
                                                    <p className="text-sm font-medium text-zinc-700 dark:text-zinc-300">
                                                        {selectedFile.name}
                                                    </p>
                                                    <p className="text-sm text-zinc-500 dark:text-zinc-400">
                                                        {(
                                                            selectedFile.size /
                                                            1024
                                                        ).toFixed(2)}{" "}
                                                        KB
                                                    </p>
                                                </div>
                                            </div>
                                            <Btn01
                                                onClick={this.handleClearFile}
                                            >
                                                Realizar Novo Upload
                                            </Btn01>
                                        </div>

                                        <div className="space-y-2">
                                            <h3 className="font-medium">
                                                Estatísticas:
                                            </h3>
                                            <ul className="list-disc list-inside space-y-1 text-sm">
                                                <li>
                                                    Número de linhas:{" "}
                                                    {
                                                        fileContent
                                                            .trim()
                                                            .split("\n").length
                                                    }
                                                </li>
                                                <li>
                                                    Número de cartões
                                                    identificados:{" "}
                                                    {identifiedCards}
                                                </li>
                                                <li>
                                                    Número de cartões não
                                                    identificados:{" "}
                                                    {unidentifiedCards}
                                                </li>
                                                <li>
                                                    Número de hashes enviadas
                                                    com sucesso:{" "}
                                                    {successfulHashes}
                                                </li>
                                                <li>
                                                    Número de hashes com erros:{" "}
                                                    {errorHashes}
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="mt-4 flex justify-center">
                                            <Button
                                                onClick={this.handleSendHashes}
                                                disabled={
                                                    sending ||
                                                    hashesOutput.length === 0 ||
                                                    !vendorToken
                                                }
                                                loading={sending}
                                            >
                                                Enviar Hashes
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default HashGuard;
