import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { jwtDecode } from "jwt-decode";
import axiosInstance, { setAuthToken } from "./axiosConfig";
import DOMPurify from "dompurify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    X,
    ShoppingCart,
    CreditCard,
    User,
    Lock,
    Calendar,
    Landmark,
    Sparkles,
    DollarSign,
    Share2,
    Zap,
    CheckCircle,
    XCircle,
    Loader2,
} from "lucide-react";
import {
    FaCcVisa,
    FaCcMastercard,
    FaCcAmex,
    FaCcDiscover,
} from "react-icons/fa";

const Button = React.forwardRef(
    ({ className, variant = "default", size = "default", ...props }, ref) => {
        const classes = clsx(
            "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
            {
                "bg-primary text-primary-foreground hover:bg-primary/90":
                    variant === "default",
                "bg-destructive text-destructive-foreground hover:bg-destructive/90":
                    variant === "destructive",
                "border border-input bg-transparent hover:bg-accent hover:text-accent-foreground":
                    variant === "outline",
                "bg-secondary text-secondary-foreground hover:bg-secondary/80":
                    variant === "secondary",
                "hover:bg-accent hover:text-accent-foreground bg-transparent":
                    variant === "ghost",
                "text-primary underline-offset-4 hover:underline":
                    variant === "link",
            },
            {
                "h-10 px-4 py-2": size === "default",
                "h-9 rounded-md px-3": size === "sm",
                "h-11 rounded-md px-8": size === "lg",
                "h-10 w-10": size === "icon",
            },
            className,
        );
        return <button className={classes} ref={ref} {...props} />;
    },
);
Button.displayName = "Button";

const Input = React.forwardRef(
    ({ className, type = "text", ...props }, ref) => {
        const classes = clsx(
            "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
            className,
        );
        return <input type={type} className={classes} ref={ref} {...props} />;
    },
);
Input.displayName = "Input";

const Card = React.forwardRef(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={clsx(
            "rounded-lg border bg-card text-card-foreground shadow-sm transition-colors duration-300 ease-in-out hover:shadow-lg hover:-translate-y-1",
            className,
        )}
        {...props}
    />
));
Card.displayName = "Card";

const CardHeader = React.forwardRef(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={clsx("flex flex-col space-y-1.5 p-4", className)}
        {...props}
    />
));
CardHeader.displayName = "CardHeader";

const CardTitle = React.forwardRef(({ className, ...props }, ref) => (
    <h3
        ref={ref}
        className={clsx(
            "text-base font-semibold leading-none tracking-tight",
            className,
        )}
        {...props}
    />
));
CardTitle.displayName = "CardTitle";

const CardContent = React.forwardRef(({ className, ...props }, ref) => (
    <div ref={ref} className={clsx("p-4 pt-0", className)} {...props} />
));
CardContent.displayName = "CardContent";

const CardFooter = React.forwardRef(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={clsx("flex items-center p-4 pt-0", className)}
        {...props}
    />
));
CardFooter.displayName = "CardFooter";

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;
    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };
    return (
        <div
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4"
            onClick={handleOverlayClick}
        >
            <div className="relative bg-background rounded-lg shadow-lg w-full max-w-lg p-6 overflow-auto max-h-full">
                <button
                    onClick={onClose}
                    className="absolute top-3 right-3 text-muted-foreground hover:text-foreground"
                >
                    <X className="h-6 w-6" />
                </button>
                {children}
            </div>
        </div>
    );
};

const getBrandIcon = (brand) => {
    switch ((brand || "").toLowerCase()) {
        case "visa":
            return <FaCcVisa className="h-6 w-6" />;
        case "mastercard":
            return <FaCcMastercard className="h-6 w-6" />;
        case "american express":
        case "amex":
            return <FaCcAmex className="h-6 w-6" />;
        case "discover":
            return <FaCcDiscover className="h-6 w-6" />;
        default:
            return <CreditCard className="h-6 w-6" />;
    }
};

const SmartBuyModal = ({
    isOpen,
    onClose,
    originalCard,
    similarCards = [],
    currentStep,
    currentCardIndex,
    purchaseStatus,
    showError,
    onConfirm,
}) => {
    const [hasConfirmed, setHasConfirmed] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        if (!isOpen) {
            setHasConfirmed(false);
            setIsProcessing(false);
        }
    }, [isOpen]);

    useEffect(() => {
        return () => {
            setHasConfirmed(false);
            setIsProcessing(false);
        };
    }, []);

    if (!isOpen || !originalCard) return null;

    const allCards = [originalCard, ...similarCards];
    const currentCard = allCards[currentCardIndex];
    const isLastAttempt = currentStep === allCards.length - 1;
    const allAttemptsFailed = isLastAttempt && purchaseStatus === "error";

    const handleConfirm = () => {
        setHasConfirmed(true);
        setIsProcessing(true);
        onConfirm(originalCard);
    };

    const handleClose = () => {
        if (!isProcessing) {
            setHasConfirmed(false);
            setIsProcessing(false);
            onClose();
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 p-3 md:p-4">
            <div className="fixed inset-0 bg-black/80" onClick={handleClose} />
            <div className="relative bg-card w-full max-w-lg rounded-lg overflow-y-auto max-h-[90vh] md:max-h-[85vh]">
                {!hasConfirmed ? (
                    <div className="flex flex-col">
                        <div className="bg-background p-4 md:p-6 flex items-start justify-between sticky top-0 z-10">
                            <div className="flex items-center gap-2 md:gap-3">
                                <Sparkles className="w-5 h-5 md:w-6 md:h-6 text-primary" />
                                <div>
                                    <h2 className="text-lg md:text-xl font-semibold text-card-foreground">
                                        Sistema Smart Buy
                                    </h2>
                                    <p className="text-xs md:text-sm text-muted-foreground">
                                        Sistema avançado de compra inteligente
                                    </p>
                                </div>
                            </div>
                            <button
                                onClick={handleClose}
                                className="text-muted-foreground hover:text-card-foreground"
                            >
                                <X className="w-5 h-5" />
                            </button>
                        </div>

                        <div className="p-4 md:p-6 space-y-4 md:space-y-6">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                                <div className="bg-background rounded-lg p-3 md:p-4">
                                    <div className="flex gap-2 md:gap-3 items-start">
                                        <Sparkles className="w-5 h-5 text-primary shrink-0" />
                                        <div>
                                            <h3 className="font-medium text-sm md:text-base text-card-foreground">
                                                Processo Inteligente
                                            </h3>
                                            <p className="text-xs md:text-sm text-muted-foreground">
                                                Checagem automática de até{" "}
                                                {similarCards.length} cartões
                                                similares
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="bg-background rounded-lg p-3 md:p-4">
                                    <div className="flex gap-2 md:gap-3 items-start">
                                        <CheckCircle className="w-5 h-5 text-primary shrink-0" />
                                        <div>
                                            <h3 className="font-medium text-sm md:text-base text-card-foreground">
                                                Garantia de Eficiência
                                            </h3>
                                            <p className="text-xs md:text-sm text-muted-foreground">
                                                Processo otimizado com pausa
                                                automática
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-background rounded-lg p-3 md:p-4">
                                <h3 className="text-xs md:text-sm font-medium text-muted-foreground mb-3 md:mb-4">
                                    Cartão Principal
                                </h3>
                                <div className="grid grid-cols-2 gap-x-4 md:gap-x-12 gap-y-3 md:gap-y-4">
                                    <div className="flex items-center gap-2">
                                        <CreditCard className="w-4 h-4 md:w-5 md:h-5 text-muted-foreground shrink-0" />
                                        <div>
                                            <p className="text-xs text-muted-foreground">
                                                BIN
                                            </p>
                                            <p className="text-sm md:text-base font-medium text-card-foreground">
                                                {originalCard.bin}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <Calendar className="w-4 h-4 md:w-5 md:h-5 text-muted-foreground shrink-0" />
                                        <div>
                                            <p className="text-xs text-muted-foreground">
                                                Validade
                                            </p>
                                            <p className="text-sm md:text-base font-medium text-card-foreground">
                                                {originalCard.month}/
                                                {originalCard.year}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <span className="w-4 h-4 md:w-5 md:h-5 text-muted-foreground shrink-0">
                                            💎
                                        </span>
                                        <div>
                                            <p className="text-xs text-muted-foreground">
                                                Nível
                                            </p>
                                            <p className="text-sm md:text-base font-medium text-card-foreground">
                                                {originalCard.level}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <Landmark className="w-4 h-4 md:w-5 md:h-5 text-muted-foreground shrink-0" />
                                        <div>
                                            <p className="text-xs text-muted-foreground">
                                                Banco
                                            </p>
                                            <p className="text-sm md:text-base font-medium text-card-foreground truncate max-w-[120px] md:max-w-[180px]">
                                                {originalCard.bank}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {similarCards?.length > 0 && (
                                <div className="bg-background rounded-lg p-3 md:p-4 flex items-start gap-2 md:gap-3">
                                    <span className="w-4 h-4 md:w-5 md:h-5 text-primary shrink-0">
                                        ⚠️
                                    </span>
                                    <div>
                                        <h4 className="text-sm md:text-base font-medium text-card-foreground">
                                            {similarCards.length} Cartões
                                            Alternativos
                                        </h4>
                                        <p className="text-xs md:text-sm text-muted-foreground mt-1">
                                            Cartões similares serão testados
                                            automaticamente
                                        </p>
                                    </div>
                                </div>
                            )}

                            <div className="flex justify-end gap-2 md:gap-3 pt-4 border-t border-border">
                                <Button
                                    variant="ghost"
                                    onClick={handleClose}
                                    className="text-sm"
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    onClick={handleConfirm}
                                    className="gap-2 text-sm"
                                >
                                    <Sparkles className="w-4 h-4" />
                                    Iniciar Smart Buy
                                </Button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="p-4 md:p-6 space-y-4 md:space-y-6">
                        <div className="flex flex-col items-center">
                            <div className="w-14 h-14 md:w-16 md:h-16 rounded-full bg-background flex items-center justify-center mb-3 md:mb-4">
                                {purchaseStatus === "success" ? (
                                    <CheckCircle className="w-7 h-7 md:w-8 md:h-8 text-customGreen" />
                                ) : allAttemptsFailed ? (
                                    <XCircle className="w-7 h-7 md:w-8 md:h-8 text-destructive" />
                                ) : (
                                    <Loader2 className="w-7 h-7 md:w-8 md:h-8 text-primary animate-spin" />
                                )}
                            </div>

                            <h3 className="text-base md:text-lg font-medium text-center mb-4 text-card-foreground px-2">
                                {purchaseStatus === "success"
                                    ? "Compra Realizada com Sucesso!"
                                    : allAttemptsFailed
                                      ? "Todas as tentativas foram realizadas"
                                      : purchaseStatus === "error"
                                        ? "Buscando próximo cartão..."
                                        : "Processando transação..."}
                            </h3>

                            <div className="w-full max-w-xs md:max-w-sm">
                                <div className="h-2 w-full bg-background rounded-full overflow-hidden">
                                    <div
                                        className={`h-full transition-all duration-500 rounded-full ${
                                            purchaseStatus === "success"
                                                ? "bg-customGreen"
                                                : allAttemptsFailed
                                                  ? "bg-destructive"
                                                  : "bg-primary"
                                        }`}
                                        style={{
                                            width: `${((currentStep + 1) * 100) / allCards.length}%`,
                                        }}
                                    />
                                </div>
                                <p className="text-xs md:text-sm text-center text-muted-foreground mt-2">
                                    {purchaseStatus === "success"
                                        ? "Smart Buy finalizado com sucesso!"
                                        : allAttemptsFailed
                                          ? "Processo finalizado"
                                          : `Tentativa ${currentStep + 1} de ${allCards.length}`}
                                </p>
                            </div>
                        </div>

                        {currentCard && (
                            <div className="bg-background rounded-lg p-3 md:p-4">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center gap-2">
                                        <CreditCard className="w-4 h-4 md:w-5 md:h-5 text-muted-foreground" />
                                        <div>
                                            <h4 className="text-sm md:text-base font-medium text-card-foreground flex items-center gap-2">
                                                {currentCard.bin}
                                                {showError && (
                                                    <XCircle className="w-4 h-4 text-destructive" />
                                                )}
                                                {purchaseStatus ===
                                                    "success" && (
                                                    <CheckCircle className="w-4 h-4 text-customGreen" />
                                                )}
                                            </h4>
                                            <p className="text-xs md:text-sm text-muted-foreground">
                                                Válido até {currentCard.month}/
                                                {currentCard.year}
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className={`px-2 md:px-3 py-1 rounded-full text-xs font-medium ${
                                            purchaseStatus === "success"
                                                ? "bg-background/50 text-customGreen"
                                                : showError
                                                  ? "bg-background/50 text-destructive"
                                                  : "bg-background/50 text-primary"
                                        }`}
                                    >
                                        {purchaseStatus === "success"
                                            ? "Aprovado"
                                            : showError
                                              ? "Falhou"
                                              : "Processando"}
                                    </div>
                                </div>
                            </div>
                        )}

                        {(allAttemptsFailed ||
                            purchaseStatus === "success") && (
                            <div className="flex justify-center">
                                <Button
                                    onClick={handleClose}
                                    variant="outline"
                                    className="w-full max-w-[160px] md:max-w-[200px] text-sm"
                                >
                                    Fechar
                                </Button>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

const Marketplace = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [username, setUsername] = useState(null);
    const [userBalance, setUserBalance] = useState(0);
    const [cards, setCards] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedCard, setSelectedCard] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Estados do SmartBuy
    const [isSmartBuyModalOpen, setIsSmartBuyModalOpen] = useState(false);
    const [cardToBuy, setCardToBuy] = useState(null);
    const [similarCards, setSimilarCards] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [currentCardIndex, setCurrentCardIndex] = useState(0);
    const [showError, setShowError] = useState(false);
    const [purchaseStatus, setPurchaseStatus] = useState("idle");

    // Pagination
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    // Purchase mode
    const [purchaseMode, setPurchaseMode] = useState("cart"); // default

    const checkAuthentication = () => {
        const token = localStorage.getItem("token");
        if (!token) {
            setIsAuthenticated(false);
            setUsername(null);
            return;
        }
        try {
            const decoded = jwtDecode(token);
            if (decoded.exp * 1000 < Date.now()) {
                localStorage.removeItem("token");
                setAuthToken(null);
                setIsAuthenticated(false);
                setUsername(null);
                return;
            }
            setAuthToken(token);
            setIsAuthenticated(true);
            setUsername(decoded.user);
        } catch (error) {
            console.error("Erro ao verificar autenticação:", error);
            localStorage.removeItem("token");
            setAuthToken(null);
            setIsAuthenticated(false);
            setUsername(null);
        }
    };

    const fetchUserSettings = async () => {
        try {
            const response = await axiosInstance.get("/user-settings");
            setPurchaseMode(response.data?.settings?.purchaseMode || "cart");
        } catch (error) {
            console.error("Erro ao buscar configurações:", error);
            setPurchaseMode("cart");
        }
    };

    useEffect(() => {
        checkAuthentication();
        const interval = setInterval(checkAuthentication, 5 * 60 * 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            fetchUserBalance();
            fetchUserSettings();
            fetchCards(1, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    const fetchUserBalance = async () => {
        try {
            const response = await axiosInstance.get("/user-info");
            const balance = response.data?.user_info?.balance || 0;
            setUserBalance(balance);
            localStorage.setItem("userBalance", balance.toString());
        } catch (error) {
            console.error("Erro ao obter saldo do usuário:", error);
            setUserBalance(0);
            localStorage.setItem("userBalance", "0");
        }
    };

    const fetchCards = async (pageNumber = 1, reset = false) => {
        setLoading(true);
        setError(null);
        try {
            const response = await axiosInstance.get(
                `/get-cards-hash?page=${pageNumber}&limit=100`,
            );
            const fetchedCards = response.data?.cards || [];
            const pagination = response.data?.pagination || {};
            const newPage = pagination.current_page || pageNumber;
            const newTotalPages = pagination.total_pages || 1;

            setPage(newPage);
            setTotalPages(newTotalPages);

            setCards((prevCards) =>
                reset ? fetchedCards : [...prevCards, ...fetchedCards],
            );
        } catch (err) {
            console.error("Erro ao buscar cartões:", err);
            setError("Falha ao carregar os cartões.");
            setCards([]);
            setPage(1);
            setTotalPages(1);
        } finally {
            setLoading(false);
        }
    };

    const handleLoadMore = () => {
        if (page < totalPages && !loading) {
            fetchCards(page + 1, false);
        }
    };

    const openModal = (card) => {
        setSelectedCard(card);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedCard(null);
        setIsModalOpen(false);
    };

    const purchaseCard = async (card) => {
        if (!username) {
            toast.error("Usuário não identificado para compra.");
            return;
        }
        try {
            const response = await axiosInstance.post(
                "/dev/purchase_marketplace",
                {
                    hash: card.hash,
                    username: username,
                },
            );
            if (response.status === 200) {
                toast.success("Compra realizada com sucesso!");
                setUserBalance(response.data.new_balance || 0);
                setCards((prevCards) =>
                    prevCards.filter((c) => c.hash !== card.hash),
                );
            } else {
                toast.error(response.data.error || "Falha na compra.");
            }
        } catch (error) {
            console.error("Erro ao processar a compra:", error);
            if (
                error.response &&
                error.response.data &&
                error.response.data.error
            ) {
                toast.error(
                    `Erro ao finalizar compra: ${error.response.data.error}`,
                );
            } else {
                toast.error("Ocorreu um erro ao processar sua compra.");
            }
        }
    };

    const handleSmartBuy = async (card) => {
        resetSmartBuyStates();
        try {
            const similarFilters = [
                { type: "bin", value: card.bin },
                { type: "level", value: card.level },
                { type: "expiry", value: `${card.month}/${card.year}` },
            ];
            const params = new URLSearchParams();
            params.append("filters", JSON.stringify(similarFilters));
            const response = await axiosInstance.get(
                `/get-cards-hash?${params.toString()}`,
            );
            const foundSimilarCards = response.data.cards
                .filter((c) => c.hash !== card.hash)
                .slice(0, 3);
            setSimilarCards(foundSimilarCards);
            setCardToBuy(card);
            setIsSmartBuyModalOpen(true);
        } catch (error) {
            console.error("Erro ao buscar cartões similares:", error);
            toast.error("Erro ao buscar cartões similares");
        }
    };

    const resetSmartBuyStates = () => {
        setIsSmartBuyModalOpen(false);
        setSimilarCards([]);
        setCurrentStep(0);
        setCurrentCardIndex(0);
        setPurchaseStatus("idle");
        setShowError(false);
        setCardToBuy(null);
        setIsProcessing(false);
    };

    const processSmartBuy = async (originalCard) => {
        setIsProcessing(true);
        setPurchaseStatus("idle");
        setShowError(false);
        setCurrentStep(0);
        setCurrentCardIndex(0);

        const allCards = [originalCard, ...similarCards];
        for (let i = 0; i < allCards.length; i++) {
            setCurrentCardIndex(i);
            setCurrentStep(i);
            setPurchaseStatus("processing");
            setShowError(false);

            try {
                const response = await axiosInstance.post(
                    "/dev/purchase_marketplace",
                    {
                        hash: allCards[i].hash,
                        username: username,
                    },
                );

                if (response.status === 200 && !response.data.error) {
                    setUserBalance(response.data.new_balance || 0);
                    localStorage.setItem(
                        "userBalance",
                        (response.data.new_balance || 0).toString(),
                    );
                    setPurchaseStatus("success");
                    toast.success("Compra realizada com sucesso!");
                    setCards((prevCards) =>
                        prevCards.filter((c) => c.hash !== allCards[i].hash),
                    );
                    await new Promise((resolve) => setTimeout(resolve, 2000));
                    setIsSmartBuyModalOpen(false);
                    break;
                } else {
                    setPurchaseStatus("error");
                    setShowError(true);
                    const errorMsg =
                        response.data.error || "Falha desconhecida.";
                    toast.error(`Falha na tentativa: ${errorMsg}`);
                    await new Promise((resolve) => setTimeout(resolve, 2000));
                }
            } catch (error) {
                setPurchaseStatus("error");
                setShowError(true);
                toast.error(
                    error.response?.data?.error || "Erro ao processar a compra",
                );
                await new Promise((resolve) => setTimeout(resolve, 2000));
            }
        }
        setIsProcessing(false);
        setPurchaseStatus("idle");
    };

    const handleQuickBuy = (card) => {
        purchaseCard(card);
    };

    const shareCardLink = (card) => {
        const cardId = card.hash;
        const link = `${window.location.origin}/?card=${cardId}`;
        navigator.clipboard
            .writeText(link)
            .then(() => {
                toast.success("Link copiado para a área de transferência!");
            })
            .catch((err) => {
                console.error("Falha ao copiar o link: ", err);
            });
    };

    const formattedBalance = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
    }).format(userBalance || 0);

    if (!isAuthenticated) {
        return (
            <div className="app-container">
                <p className="text-center mt-20">
                    Faça login para acessar o Marketplace.
                </p>
                <ToastContainer position="top-right" autoClose={5000} />
            </div>
        );
    }

    return (
        <div className="flex flex-col h-screen bg-background text-foreground">
            <div className="flex items-center justify-between p-4 border-b border-input">
                <div className="flex items-center gap-4">
                    <p className="font-semibold">Marketplace</p>
                </div>
                <div className="flex items-center gap-2">
                    <div className="flex items-center bg-secondary rounded-md overflow-hidden">
                        <span className="px-4 py-2 font-semibold text-secondary-foreground">
                            Saldo: {formattedBalance}
                        </span>
                    </div>
                </div>
            </div>

            <div className="flex-1 overflow-y-auto p-4">
                {loading && (
                    <p className="text-center">Carregando cartões...</p>
                )}
                {error && <p className="text-center text-red-500">{error}</p>}

                {cards?.length > 0 ? (
                    <>
                        <div className="grid gap-4 sm:gap-6 md:gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
                            {cards.map((card, index) => (
                                <Card
                                    key={index}
                                    className="bg-card text-card-foreground cursor-pointer w-full relative overflow-hidden rounded-lg shadow-lg transition-all duration-300 ease-in-out hover:shadow-xl hover:-translate-y-1"
                                    onClick={() => openModal(card)}
                                >
                                    <div className="absolute inset-0 flex items-center justify-center overflow-hidden">
                                        <div className="text-[30rem] transform scale-150 text-gray-300 opacity-10 pointer-events-none">
                                            {getBrandIcon(card.vendor)}
                                        </div>
                                    </div>
                                    <div className="relative z-10 p-4 sm:p-6">
                                        <CardHeader className="p-0">
                                            <CardTitle className="flex items-center justify-between text-base sm:text-lg">
                                                <span className="flex items-center">
                                                    {getBrandIcon(card.vendor)}
                                                    <span className="ml-2 font-semibold">
                                                        BIN: {card.bin}
                                                    </span>
                                                </span>
                                                <span className="text-sm md:text-base font-medium bg-gray-200 text-gray-800 px-2 py-1 rounded-full">
                                                    {card.level}
                                                </span>
                                            </CardTitle>
                                        </CardHeader>
                                        <CardContent className="mt-4 p-0">
                                            <p className="flex items-center mb-2 text-sm">
                                                <User className="h-5 w-5 mr-2 flex-shrink-0" />
                                                <span className="truncate">
                                                    Nome: {card.nome}
                                                </span>
                                            </p>
                                            <p className="flex items-center mb-2 text-sm">
                                                <Lock className="h-5 w-5 mr-2 flex-shrink-0" />
                                                <span className="truncate">
                                                    CPF: {card.cpf}
                                                </span>
                                            </p>
                                            <p className="flex items-center mb-2 text-sm">
                                                <Calendar className="h-5 w-5 mr-2 flex-shrink-0" />
                                                <span>
                                                    Validade: {card.month}/
                                                    {card.year}
                                                </span>
                                            </p>
                                            <p className="flex items-center mb-2 text-sm">
                                                <Landmark className="h-5 w-5 mr-2 flex-shrink-0" />
                                                <span className="truncate">
                                                    Banco: {card.bank}
                                                </span>
                                            </p>
                                            <p className="flex items-center mb-2 text-sm">
                                                <DollarSign className="h-5 w-5 mr-2 flex-shrink-0" />
                                                <span className="truncate">
                                                    Preço: R${" "}
                                                    {parseFloat(
                                                        card.price,
                                                    ).toFixed(2)}
                                                </span>
                                            </p>
                                        </CardContent>
                                        <CardFooter className="flex justify-between items-center p-0">
                                            <Button
                                                variant="ghost"
                                                size="icon"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    shareCardLink(card);
                                                }}
                                                className="h-10 w-10 hover:bg-accent hover:text-accent-foreground rounded-full transition-colors"
                                            >
                                                <Share2 className="h-5 w-5" />
                                                <span className="sr-only">
                                                    Compartilhar Link
                                                </span>
                                            </Button>

                                            {/* Botões de compra baseados no purchaseMode */}
                                            {purchaseMode === "cart" ? (
                                                <Button
                                                    size="icon"
                                                    className="h-10 w-10 bg-customGreen text-customGreen-foreground rounded-full flex items-center justify-center transition-transform duration-200 hover:bg-customGreen-hover hover:scale-110"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        // Adicionar ao carrinho (não definido no marketplace, mas mantendo coerência)
                                                        toast.info(
                                                            "Modo carrinho não implementado no marketplace.",
                                                        );
                                                    }}
                                                >
                                                    <ShoppingCart className="h-5 w-5" />
                                                    <span className="sr-only">
                                                        Adicionar ao carrinho
                                                    </span>
                                                </Button>
                                            ) : purchaseMode === "smart" ? (
                                                <Button
                                                    size="icon"
                                                    variant="ghost"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleSmartBuy(card);
                                                    }}
                                                    className="h-10 w-10 hover:bg-accent hover:text-accent-foreground rounded-full transition-colors bg-blue-50"
                                                >
                                                    <Sparkles className="h-5 w-5 text-blue-500" />
                                                    <span className="sr-only">
                                                        Compra Inteligente
                                                    </span>
                                                </Button>
                                            ) : (
                                                <Button
                                                    size="icon"
                                                    variant="ghost"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleQuickBuy(card);
                                                    }}
                                                    className="h-10 w-10 hover:bg-accent hover:text-accent-foreground rounded-full transition-colors"
                                                >
                                                    <Zap className="h-5 w-5" />
                                                    <span className="sr-only">
                                                        Compra Instantânea
                                                    </span>
                                                </Button>
                                            )}
                                        </CardFooter>
                                    </div>
                                </Card>
                            ))}
                        </div>

                        {/* Botão para carregar mais */}
                        {page < totalPages && !loading && (
                            <div className="flex justify-center mt-6">
                                <Button onClick={handleLoadMore}>
                                    Carregar Mais
                                </Button>
                            </div>
                        )}
                    </>
                ) : (
                    !loading &&
                    !error && (
                        <p className="col-span-full text-center">
                            Nenhum cartão encontrado.
                        </p>
                    )
                )}
            </div>

            {selectedCard && (
                <Modal isOpen={isModalOpen} onClose={closeModal}>
                    <h2 className="text-2xl font-bold mb-4">
                        Detalhes do Cartão
                    </h2>
                    <p className="mb-2">
                        <strong>BIN:</strong>{" "}
                        {DOMPurify.sanitize(selectedCard.bin)}
                    </p>
                    <p className="mb-2">
                        <strong>Validade:</strong>{" "}
                        {DOMPurify.sanitize(selectedCard.month)}/
                        {DOMPurify.sanitize(selectedCard.year)}
                    </p>
                    <p className="mb-2">
                        <strong>Vendor:</strong>{" "}
                        {DOMPurify.sanitize(selectedCard.vendor)}
                    </p>
                    <p className="mb-2">
                        <strong>Tipo:</strong>{" "}
                        {DOMPurify.sanitize(selectedCard.type)}
                    </p>
                    <p className="mb-2">
                        <strong>Nível:</strong>{" "}
                        {DOMPurify.sanitize(selectedCard.level)}
                    </p>
                    <p className="mb-2">
                        <strong>Banco:</strong>{" "}
                        {DOMPurify.sanitize(selectedCard.bank)}
                    </p>
                    <p className="mb-2">
                        <strong>País:</strong>{" "}
                        {DOMPurify.sanitize(selectedCard.country)}
                    </p>
                    <p className="mb-2">
                        <strong>Nome:</strong>{" "}
                        {DOMPurify.sanitize(selectedCard.nome)}
                    </p>
                    <p className="mb-2">
                        <strong>CPF:</strong>{" "}
                        {DOMPurify.sanitize(selectedCard.cpf)}
                    </p>
                </Modal>
            )}

            <SmartBuyModal
                isOpen={isSmartBuyModalOpen}
                onClose={resetSmartBuyStates}
                originalCard={cardToBuy}
                similarCards={similarCards}
                currentStep={currentStep}
                currentCardIndex={currentCardIndex}
                purchaseStatus={purchaseStatus}
                showError={showError}
                onConfirm={processSmartBuy}
            />

            <ToastContainer
                position="top-right"
                autoClose={5000}
                style={{
                    top: "7rem",
                    right: "1rem",
                }}
            />
        </div>
    );
};

export default Marketplace;
