import React, { useEffect, useState } from "react";
import {
    Loader2,
    MapPin,
    UserCircle,
    Hash,
    X,
    DollarSign,
    Image,
    FileText,
    Eye,
    Download,
    User,
    IdCard,
} from "lucide-react";
import clsx from "clsx";

const Button = React.forwardRef(
    ({ className, variant = "default", size = "default", ...props }, ref) => {
        const classes = clsx(
            "inline-flex items-center justify-center rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
            {
                "bg-primary text-primary-foreground hover:bg-primary/90":
                    variant === "default",
                "bg-destructive text-destructive-foreground hover:bg-destructive/90":
                    variant === "destructive",
                "border border-input bg-background hover:bg-accent hover:text-accent-foreground":
                    variant === "outline",
                "bg-secondary text-secondary-foreground hover:bg-secondary/80":
                    variant === "secondary",
                "hover:bg-accent hover:text-accent-foreground":
                    variant === "ghost",
                "text-primary underline-offset-4 hover:underline":
                    variant === "link",
            },
            {
                "h-10 px-4 py-2 text-sm": size === "default",
                "h-9 rounded-md px-3 text-sm": size === "sm",
                "h-11 rounded-md px-6 text-base": size === "lg",
                "h-10 w-10": size === "icon",
            },
            className,
        );
        return <button className={classes} ref={ref} {...props} />;
    },
);
Button.displayName = "Button";

const Card = React.forwardRef(({ className, ...props }, ref) => {
    return (
        <div
            ref={ref}
            className={clsx(
                "rounded-xl border bg-card text-card-foreground shadow p-6",
                className,
            )}
            {...props}
        />
    );
});
Card.displayName = "Card";

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div
            className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4"
            onClick={onClose}
        >
            <div
                className="bg-card text-card-foreground rounded-xl p-6 w-full max-w-lg relative max-h-[90vh] overflow-y-auto"
                onClick={(e) => e.stopPropagation()}
            >
                <button
                    onClick={onClose}
                    className="absolute right-4 top-4 text-muted-foreground hover:text-foreground transition-colors"
                >
                    <X className="h-5 w-5" />
                </button>
                {children}
            </div>
        </div>
    );
};

const History = () => {
    const [docs, setDocs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(50);
    const [totalDocs, setTotalDocs] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [selectedDocFiles, setSelectedDocFiles] = useState([]);
    const [previewStates, setPreviewStates] = useState({});

    const token = localStorage.getItem("token");

    const fetchMyDocs = async (page = 1) => {
        try {
            setLoading(true);
            const response = await fetch(
                `https://api.astrashopbot.cc/api/my_docs?page=${page}&per_page=${perPage}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                },
            );
            const data = await response.json();
            if (data.error) {
                setError(data.error);
            } else {
                setDocs(data.docs || []);
                setTotalDocs(data.total_docs || 0);
                setCurrentPage(data.page || 1);
            }
        } catch (err) {
            setError("Erro ao carregar histórico de compras");
        } finally {
            setLoading(false);
        }
    };

    const fetchDocFiles = async (cpf) => {
        try {
            const response = await fetch(
                `https://api.astrashopbot.cc/api/doc_files?cpf=${cpf}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                },
            );
            const data = await response.json();
            if (data.error) {
                console.error(data.error);
                setSelectedDocFiles([]);
            } else {
                setSelectedDocFiles(data.files || []);
            }
        } catch (err) {
            console.error("Erro ao carregar arquivos do documento", err);
            setSelectedDocFiles([]);
        }
    };

    useEffect(() => {
        fetchMyDocs(currentPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const totalPages = Math.ceil(totalDocs / perPage);

    const handleViewFiles = (doc) => {
        setSelectedDoc(doc);
        setSelectedDocFiles([]);
        setIsModalOpen(true);
        // Assim que o modal abre, já buscamos os arquivos
        fetchDocFiles(doc.cpf);
    };

    const FileIcon = ({ ext }) => {
        const lowerExt = ext.toLowerCase();
        if (lowerExt === "pdf")
            return <FileText className="h-4 w-4 shrink-0" />;
        if (["jpg", "jpeg", "png"].includes(lowerExt))
            return <Image className="h-4 w-4 shrink-0" />;
        return <FileText className="h-4 w-4 shrink-0" />;
    };

    return (
        <div className="min-h-screen p-4 sm:p-6 md:p-8 bg-background">
            <div className="max-w-7xl mx-auto space-y-8">
                <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
                    <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold">
                        Histórico de Compras
                    </h1>
                </div>

                {loading && (
                    <div className="flex justify-center items-center py-12">
                        <Loader2 className="h-8 w-8 animate-spin" />
                    </div>
                )}

                {error && (
                    <div className="text-center py-12 text-destructive">
                        {error}
                    </div>
                )}

                {!loading && !error && docs.length > 0 && (
                    <>
                        <div className="grid gap-6 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
                            {docs.map((doc) => {
                                let tipoFormatado = doc.tipo;
                                if (doc.tipo === "mix") {
                                    tipoFormatado = "RG e CNH";
                                } else if (doc.tipo === "rg") {
                                    tipoFormatado = "RG";
                                } else if (doc.tipo === "cnh") {
                                    tipoFormatado = "CNH";
                                }
                                return (
                                    <Card key={doc._id}>
                                        <div className="flex flex-col h-full">
                                            <div className="flex items-center justify-between mb-6">
                                                <div className="flex items-center gap-2">
                                                    <User className="h-4 w-4 shrink-0 text-muted-foreground" />
                                                    <span className="text-lg font-semibold break-words">
                                                        {doc.nome}
                                                    </span>
                                                </div>
                                                <div className="flex items-center text-sm font-medium">
                                                    <DollarSign className="h-4 w-4 mr-1 shrink-0" />
                                                    <span>R$ 10,00</span>
                                                </div>
                                            </div>

                                            <div className="flex flex-col space-y-4 flex-1">
                                                <div className="flex items-center gap-2">
                                                    <Hash className="h-4 w-4 shrink-0 text-muted-foreground" />
                                                    <code className="font-mono text-xs bg-secondary px-1.5 py-0.5 rounded">
                                                        {doc.cpf}
                                                    </code>
                                                </div>

                                                <div className="flex items-center gap-2">
                                                    <UserCircle className="h-4 w-4 shrink-0 text-muted-foreground" />
                                                    <span>
                                                        {doc.idade} anos |{" "}
                                                        {doc.sexo}
                                                    </span>
                                                </div>

                                                <div className="flex items-center gap-2">
                                                    <IdCard className="h-4 w-4 shrink-0 text-muted-foreground" />
                                                    <span>{tipoFormatado}</span>
                                                </div>

                                                <div className="flex items-start gap-2">
                                                    <MapPin className="h-4 w-4 shrink-0 text-muted-foreground mt-1" />
                                                    <span className="break-words">
                                                        {doc.localizacao}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="mt-6">
                                                <Button
                                                    variant="secondary"
                                                    className="w-full"
                                                    onClick={() =>
                                                        handleViewFiles(doc)
                                                    }
                                                >
                                                    Ver Arquivos
                                                </Button>
                                            </div>
                                        </div>
                                    </Card>
                                );
                            })}
                        </div>

                        <div className="flex justify-center items-center gap-4">
                            <Button
                                variant="outline"
                                onClick={() => fetchMyDocs(currentPage - 1)}
                                disabled={currentPage <= 1}
                            >
                                Anterior
                            </Button>
                            <span className="text-sm">
                                Página {currentPage} de{" "}
                                {Math.max(totalPages, 1)}
                            </span>
                            <Button
                                variant="outline"
                                onClick={() => fetchMyDocs(currentPage + 1)}
                                disabled={currentPage >= totalPages}
                            >
                                Próxima
                            </Button>
                        </div>
                    </>
                )}

                {!loading && !error && docs.length === 0 && (
                    <div className="text-center py-12 text-muted-foreground">
                        Você ainda não comprou nenhum documento.
                    </div>
                )}
            </div>

            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <div className="space-y-6">
                    <h2 className="text-xl font-semibold">
                        Arquivos do CPF {selectedDoc?.cpf}
                    </h2>

                    {selectedDocFiles.length > 0 ? (
                        <ul className="space-y-4">
                            {selectedDocFiles.map((fileObj) => {
                                const filename = fileObj.filename;
                                const ext = filename.split(".").pop() || "";
                                const lowerExt = ext.toLowerCase();
                                const isImage = ["jpg", "jpeg", "png"].includes(
                                    lowerExt,
                                );
                                const isPDF = lowerExt === "pdf";
                                const showPreview = previewStates[filename];

                                return (
                                    <li
                                        key={filename}
                                        className="border-b pb-4 last:border-b-0"
                                    >
                                        <div className="flex items-center gap-2 mb-3">
                                            <FileIcon ext={ext} />
                                            <span className="break-words font-medium">
                                                {filename}
                                            </span>
                                        </div>

                                        <div className="flex flex-wrap gap-2">
                                            <a
                                                href={fileObj.content_base64}
                                                download={filename}
                                                className="shrink-0"
                                            >
                                                <Button
                                                    variant="secondary"
                                                    size="sm"
                                                    className="gap-2"
                                                >
                                                    <Download className="h-4 w-4" />
                                                    Baixar
                                                </Button>
                                            </a>

                                            {(isImage || isPDF) && (
                                                <Button
                                                    variant="outline"
                                                    size="sm"
                                                    onClick={() =>
                                                        setPreviewStates(
                                                            (prev) => ({
                                                                ...prev,
                                                                [filename]:
                                                                    !prev[
                                                                        filename
                                                                    ],
                                                            }),
                                                        )
                                                    }
                                                    className="gap-2"
                                                >
                                                    <Eye className="h-4 w-4" />
                                                    {showPreview
                                                        ? "Ocultar Prévia"
                                                        : "Mostrar Prévia"}
                                                </Button>
                                            )}
                                        </div>

                                        {showPreview && (
                                            <div className="mt-4">
                                                {isImage && (
                                                    <img
                                                        src={
                                                            fileObj.content_base64
                                                        }
                                                        alt={filename}
                                                        className="rounded-lg border max-w-full h-auto"
                                                    />
                                                )}
                                                {isPDF && (
                                                    <embed
                                                        src={
                                                            fileObj.content_base64
                                                        }
                                                        type="application/pdf"
                                                        className="w-full h-96 rounded-lg border"
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    ) : (
                        <div className="text-center py-4 text-muted-foreground">
                            Nenhum arquivo encontrado para este CPF.
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default History;
