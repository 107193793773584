import React from "react";
import { Share2, ShoppingCart, Zap, Sparkles } from "lucide-react";

// Table Components
const Table = React.forwardRef(({ className, ...props }, ref) => (
    <div className="relative w-full overflow-auto rounded-lg border border-border bg-card">
        <table
            ref={ref}
            className={`w-full caption-bottom text-sm ${className}`}
            {...props}
        />
    </div>
));
Table.displayName = "Table";

const TableHeader = React.forwardRef(({ className, ...props }, ref) => (
    <thead
        ref={ref}
        className={`border-b border-border bg-secondary/50 ${className}`}
        {...props}
    />
));
TableHeader.displayName = "TableHeader";

const TableBody = React.forwardRef(({ className, ...props }, ref) => (
    <tbody
        ref={ref}
        className={`[&_tr:last-child]:border-0 text-tiny ${className}`}
        {...props}
    />
));
TableBody.displayName = "TableBody";

const TableRow = React.forwardRef(({ className, ...props }, ref) => (
    <tr
        ref={ref}
        className={`border-b border-border transition-colors hover:bg-secondary/50 data-[state=selected]:bg-secondary ${className}`}
        {...props}
    />
));
TableRow.displayName = "TableRow";

const TableHead = React.forwardRef(({ className, ...props }, ref) => (
    <th
        ref={ref}
        className={`h-12 px-2 md:px-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0 first:pl-4 last:pr-4 ${className}`}
        {...props}
    />
));
TableHead.displayName = "TableHead";

const TableCell = React.forwardRef(({ className, ...props }, ref) => (
    <td
        ref={ref}
        className={`px-2 md:px-4 py-3 align-middle [&:has([role=checkbox])]:pr-0 first:pl-4 last:pr-4 ${className}`}
        {...props}
    />
));
TableCell.displayName = "TableCell";

const CardTableView = ({
    cards,
    purchaseMode,
    onShare,
    onAddToCart,
    onQuickBuy,
    onSmartBuy,
    getBrandIcon,
    openModal,
}) => {
    const renderActionButtons = (card) => {
        const renderPurchaseButton = () => {
            switch (purchaseMode) {
                case "cart":
                    return (
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                onAddToCart(card);
                            }}
                            className="p-1.5 md:p-2 bg-customGreen text-customGreen-foreground rounded-full hover:bg-customGreen-hover transition-colors"
                            title="Adicionar ao carrinho"
                        >
                            <ShoppingCart className="h-4 w-4" />
                        </button>
                    );
                case "instant":
                    return (
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                onQuickBuy(card);
                            }}
                            className="p-1.5 md:p-2 hover:bg-accent rounded-full transition-colors"
                            title="Compra instantânea"
                        >
                            <Zap className="h-4 w-4" />
                        </button>
                    );
                case "smart":
                    return (
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                onSmartBuy(card);
                            }}
                            className="p-1.5 md:p-2 hover:bg-accent rounded-full transition-colors"
                            title="Smart Buy"
                        >
                            <Sparkles className="h-4 w-4 text-timeframe" />
                        </button>
                    );
                default:
                    return null;
            }
        };

        return (
            <div className="flex items-center justify-center md:justify-end gap-1 md:gap-2">
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        onShare(card);
                    }}
                    className="p-2 hover:bg-accent rounded-full transition-colors hidden md:inline-flex"
                    title="Compartilhar"
                >
                    <Share2 className="h-4 w-4" />
                </button>
                {renderPurchaseButton()}
            </div>
        );
    };

    return (
        <div className="w-full">
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead className="w-20">BIN</TableHead>
                        <TableHead className="hidden md:table-cell">
                            Bandeira
                        </TableHead>
                        <TableHead className="md:hidden w-16">Nível</TableHead>
                        <TableHead className="hidden md:table-cell w-20">
                            Validade
                        </TableHead>
                        <TableHead className="hidden lg:table-cell">
                            Nível
                        </TableHead>
                        <TableHead className="hidden md:table-cell">
                            Banco
                        </TableHead>
                        <TableHead className="hidden lg:table-cell">
                            Nome
                        </TableHead>
                        <TableHead className="w-16">Preço</TableHead>
                        <TableHead className="text-right w-12">Ações</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {cards.map((card, index) => (
                        <TableRow
                            key={index}
                            className="cursor-pointer"
                            onClick={() => openModal(card)}
                        >
                            <TableCell className="font-medium">
                                {card.bin}
                            </TableCell>
                            <TableCell className="hidden md:table-cell">
                                <div className="w-8 h-8 flex items-center">
                                    {getBrandIcon(card.vendor)}
                                </div>
                            </TableCell>
                            <TableCell className="md:hidden">
                                <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-secondary text-secondary-foreground">
                                    {card.level}
                                </span>
                            </TableCell>
                            <TableCell className="hidden md:table-cell">{`${card.month}/${card.year}`}</TableCell>
                            <TableCell className="hidden lg:table-cell">
                                <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-secondary text-secondary-foreground">
                                    {card.level}
                                </span>
                            </TableCell>
                            <TableCell className="hidden md:table-cell max-w-[200px] truncate">
                                {card.bank}
                            </TableCell>
                            <TableCell className="hidden lg:table-cell max-w-[200px] truncate">
                                {card.nome}
                            </TableCell>
                            <TableCell>
                                <span className="whitespace-nowrap">
                                    R$ {parseFloat(card.price).toFixed(2)}
                                </span>
                            </TableCell>
                            <TableCell className="text-center md:text-right">
                                {renderActionButtons(card)}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default CardTableView;
