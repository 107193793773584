import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import {
    X,
    Home,
    Settings,
    LogOut,
    ShoppingBag,
    DollarSign,
    LayoutPanelLeft,
    LayoutPanelTop,
    Hash,
    FileText,
} from "lucide-react";
import axiosInstance from "./axiosConfig";

const Button = React.forwardRef(
    ({ className, variant = "default", size = "default", ...props }, ref) => {
        const classes = clsx(
            "inline-flex items-center justify-center rounded-md text-base font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
            {
                "bg-primary text-primary-foreground hover:bg-primary/90":
                    variant === "default",
                "bg-destructive text-destructive-foreground hover:bg-destructive/90":
                    variant === "destructive",
                "border border-input bg-background hover:bg-accent hover:text-accent-foreground":
                    variant === "outline",
                "bg-secondary text-secondary-foreground hover:bg-secondary/80":
                    variant === "secondary",
                "hover:bg-accent hover:text-accent-foreground":
                    variant === "ghost",
                "text-primary underline-offset-4 hover:underline":
                    variant === "link",
            },
            {
                "h-11 px-5 py-2.5": size === "default",
                "h-10 rounded-md px-4": size === "sm",
                "h-12 rounded-md px-8": size === "lg",
                "h-11 w-11": size === "icon",
            },
            className,
        );
        return <button className={classes} ref={ref} {...props} />;
    },
);
Button.displayName = "Button";

const Menu = ({
    menuOpen,
    setMenuOpen,
    setCurrentPage,
    onLogout,
    username,
    userBalance,
}) => {
    const [profileImage, setProfileImage] = useState("");
    const [menuLayout, setMenuLayout] = useState(() => {
        return localStorage.getItem("menuLayout") || "vertical";
    });
    const menuRef = useRef(null);

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const response = await axiosInstance.get("/user-info");
                const { user_info } = response.data;
                if (user_info) {
                    setProfileImage(user_info.profile_image);
                }
            } catch (error) {
                console.error("Erro ao buscar informações do usuário:", error);
            }
        };

        if (username) {
            fetchUserInfo();
        }
    }, [username]);

    const menuClasses = clsx(
        "bg-secondary shadow-lg transition-all duration-300 ease-in-out",
        menuLayout === "vertical" && [
            "w-80 2xl:w-96",
            "h-full",
            menuOpen ? "translate-x-0" : "-translate-x-full",
            "z-40",
            "fixed left-0 top-0",
            "lg:static",
            "lg:translate-x-0",
            "lg:flex-shrink-0",
        ],
        menuLayout === "horizontal" && [
            "fixed top-0 left-0 right-0",
            "w-full h-16",
            "flex flex-row items-center justify-between",
            "px-4 py-2",
            "z-40",
        ],
    );

    let menuItems = [
        {
            icon: <Home className="h-5 w-5" />,
            text: "Início",
            onClick: () => {
                setCurrentPage("main");
                setMenuOpen(false);
            },
        },
        {
            icon: <DollarSign className="h-5 w-5" />,
            text: "Adicionar saldo",
            onClick: () => {
                setCurrentPage("add-balance");
                setMenuOpen(false);
            },
        },
        {
            icon: <ShoppingBag className="h-5 w-5" />,
            text: "Minhas Compras",
            onClick: () => {
                setCurrentPage("minhas-compras");
                setMenuOpen(false);
            },
        },
        {
            icon: <Settings className="h-5 w-5" />,
            text: "Configurações",
            onClick: () => {
                setCurrentPage("settings");
                setMenuOpen(false);
            },
        },
        {
            icon: <LogOut className="h-5 w-5" />,
            text: "Sair",
            onClick: () => {
                onLogout();
                setMenuOpen(false);
            },
        },
    ];

    // Caso seja Belfort ou fenrir, adiciona os itens extras
    if (["Belfort", "fenrir"].includes(username)) {
        menuItems.splice(
            3,
            0, // insere após "Minhas Compras"
            {
                icon: <Hash className="h-5 w-5" />,
                text: "HashGuard",
                onClick: () => {
                    setCurrentPage("hashguard");
                    setMenuOpen(false);
                },
            },
            {
                icon: <FileText className="h-5 w-5" />,
                text: "Marketplace",
                onClick: () => {
                    setCurrentPage("marketplace");
                    setMenuOpen(false);
                },
            },
            {
                icon: <FileText className="h-5 w-5" />,
                text: "Documentos",
                onClick: () => {
                    setCurrentPage("documents");
                    setMenuOpen(false);
                },
            },
            {
                icon: <FileText className="h-5 w-5" />,
                text: "History",
                onClick: () => {
                    setCurrentPage("history");
                    setMenuOpen(false);
                },
            },
        );
    }

    if (username === "Gugajipa222") {
        menuItems.splice(
            3,
            0, // insere após "Minhas Compras"
            {
                icon: <FileText className="h-5 w-5" />,
                text: "Documentos",
                onClick: () => {
                    setCurrentPage("documents");
                    setMenuOpen(false);
                },
            },
            {
                icon: <FileText className="h-5 w-5" />,
                text: "History",
                onClick: () => {
                    setCurrentPage("history");
                    setMenuOpen(false);
                },
            },
        );
    }

    return (
        <div ref={menuRef} className={menuClasses}>
            {menuLayout === "vertical" ? (
                <div className="h-full flex flex-col">
                    <div className="p-6 border-b border-border">
                        <div className="flex items-center justify-between">
                            <h2 className="text-2xl font-bold">Menu</h2>
                            <div className="flex items-center gap-2">
                                <Button
                                    variant="ghost"
                                    size="icon"
                                    onClick={() => {
                                        setMenuLayout("horizontal");
                                        localStorage.setItem(
                                            "menuLayout",
                                            "horizontal",
                                        );
                                    }}
                                    className="text-foreground"
                                >
                                    <LayoutPanelTop className="h-5 w-5" />
                                </Button>
                                <Button
                                    variant="ghost"
                                    size="icon"
                                    onClick={() => setMenuOpen(false)}
                                    className="lg:hidden"
                                >
                                    <X className="h-6 w-6" />
                                </Button>
                            </div>
                        </div>
                    </div>

                    <nav className="flex-1 overflow-y-auto p-4">
                        <ul className="space-y-2">
                            {menuItems.map((item, index) => (
                                <li key={index}>
                                    <button
                                        onClick={item.onClick}
                                        className="flex items-center gap-3 text-sm w-full p-3 rounded-md hover:bg-accent hover:text-accent-foreground transition-colors"
                                    >
                                        <span className="flex items-center justify-center">
                                            {item.icon}
                                        </span>
                                        <span className="whitespace-nowrap">
                                            {item.text}
                                        </span>
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>

                    <div className="mt-auto p-6 border-t border-border">
                        <div className="flex items-center gap-4">
                            <img
                                src={profileImage || "/profile.png"}
                                alt="Perfil"
                                className="w-12 h-12 rounded-full"
                            />
                            <div className="flex-1">
                                <p className="font-semibold">{username}</p>
                                {userBalance !== null && (
                                    <p className="text-sm text-gray-400">
                                        Saldo: R$ {userBalance.toFixed(2)}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="w-full h-full flex items-center justify-between px-4">
                    <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => {
                            setMenuLayout("vertical");
                            localStorage.setItem("menuLayout", "vertical");
                        }}
                        className="text-foreground"
                    >
                        <LayoutPanelLeft className="h-5 w-5" />
                    </Button>
                    <nav className="flex-1 flex justify-center">
                        <ul className="flex items-center space-x-2">
                            {menuItems.map((item, index) => (
                                <li key={index}>
                                    <button
                                        onClick={item.onClick}
                                        className="flex items-center gap-2 text-sm p-2 rounded-md hover:bg-accent hover:text-accent-foreground transition-colors"
                                    >
                                        <span className="flex items-center justify-center">
                                            {item.icon}
                                        </span>
                                        <span className="hidden lg:inline whitespace-nowrap">
                                            {item.text}
                                        </span>
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>
                    <div className="w-10"></div>
                </div>
            )}
        </div>
    );
};

export default Menu;
