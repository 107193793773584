// AdvancedSearch.js
import React, { useState, useEffect } from "react";
import {
    Search,
    Filter,
    Plus,
    X,
    Database,
    Check,
    ArrowLeftRight,
    ShoppingCart,
    Trash2,
} from "lucide-react";

const AdvancedSearch = ({
    onSearchChange,
    onFiltersChange,
    Button,
    Input,
    purchaseMode,
    cart,
    cards,
    removeFromCart,
    getCardId,
    setCurrentPage,
}) => {
    const [showFilters, setShowFilters] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [filters, setFilters] = useState([{ type: "brand", value: "" }]);
    const [baseStats, setBaseStats] = useState(null);
    const [activeBase, setActiveBase] = useState("cards");
    const [isLoadingBase, setIsLoadingBase] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showSwitcher, setShowSwitcher] = useState(false);

    useEffect(() => {
        fetchBaseStats();
        fetchActiveBase();
    }, []);

    useEffect(() => {
        if (showSuccess) {
            const timer = setTimeout(() => {
                setShowSuccess(false);
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [showSuccess]);

    const fetchBaseStats = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(
                "https://api.astrashopbot.cc/api/base-stats",
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );
            if (response.ok) {
                const data = await response.json();
                setBaseStats(data);
            }
        } catch (error) {
            console.error("Erro ao buscar estatísticas das bases:", error);
        }
    };

    const fetchActiveBase = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(
                "https://api.astrashopbot.cc/api/user-settings",
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );
            if (response.ok) {
                const data = await response.json();
                setActiveBase(data.settings.activeBase || "cards");
            }
        } catch (error) {
            console.error("Erro ao buscar base ativa:", error);
        }
    };

    const getBaseNumber = (baseId) => {
        switch (baseId) {
            case "cards":
                return "1";
            case "cards2":
                return "2";
            case "cards3":
                return "3";
            default:
                return "1";
        }
    };

    const getBaseStats = (baseId) => {
        switch (baseId) {
            case "cards":
                return baseStats?.base1;
            case "cards2":
                return baseStats?.base2;
            case "cards3":
                return baseStats?.base3;
            default:
                return baseStats?.base1;
        }
    };

    const switchBase = async (newBase) => {
        if (newBase === activeBase) return;

        setIsLoadingBase(true);
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(
                "https://api.astrashopbot.cc/api/switch-base",
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ base: newBase }),
                },
            );

            if (response.ok) {
                setActiveBase(newBase);
                await fetchBaseStats();
                setSearchQuery("");
                setFilters([{ type: "brand", value: "" }]);
                onSearchChange("");
                onFiltersChange([]);
                setShowSuccess(true);
            }
        } catch (error) {
            console.error("Erro ao trocar de base:", error);
        } finally {
            setIsLoadingBase(false);
        }
    };

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchQuery(value);
        onSearchChange(value);
    };

    const handleFilterTypeChange = (index, newType) => {
        const updatedFilters = [...filters];
        updatedFilters[index].type = newType;
        updatedFilters[index].value = "";
        setFilters(updatedFilters);
        onFiltersChange(updatedFilters);
    };

    const handleFilterValueChange = (index, newValue) => {
        const updatedFilters = [...filters];
        updatedFilters[index].value = newValue;
        setFilters(updatedFilters);
        onFiltersChange(updatedFilters);
    };

    const addFilter = () => {
        setFilters([...filters, { type: "brand", value: "" }]);
    };

    const removeFilter = (index) => {
        const updatedFilters = [...filters];
        updatedFilters.splice(index, 1);
        setFilters(updatedFilters);
        onFiltersChange(updatedFilters);
    };

    return (
        <>
            <div className="w-full space-y-4">
                {/* Base Switcher com Animação de Sucesso - Largura Ajustada */}
                <div className="w-full">
                    <div
                        className={`relative w-full flex items-center justify-between bg-card text-card-foreground py-2.5 px-4 rounded-lg border shadow-md overflow-hidden ${
                            showSuccess ? "animate-pulse" : ""
                        }`}
                    >
                        <div className="flex items-center gap-3">
                            <Database size={18} className="text-primary" />
                            <span className="font-medium text-sm">
                                Base {getBaseNumber(activeBase)}
                            </span>
                            {/* Indicador de Sucesso Animado */}
                            {showSuccess && (
                                <span className="flex items-center gap-1 text-xs text-customGreen animate-in fade-in slide-in-from-left-5">
                                    <Check size={14} />
                                    <span className="font-medium">
                                        Base alterada
                                    </span>
                                </span>
                            )}
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="bg-secondary rounded-lg px-3 py-1.5 flex items-center gap-2">
                                <span className="text-primary font-semibold text-sm">
                                    {getBaseStats(activeBase)?.count}
                                </span>
                                <span className="text-muted-foreground font-medium text-sm">
                                    cartões
                                </span>
                            </div>
                            <button
                                onClick={() => setShowSwitcher(true)}
                                className="bg-primary text-primary-foreground rounded-lg p-1.5 hover:bg-primary/90 transition-colors"
                            >
                                <ArrowLeftRight size={16} />
                            </button>
                        </div>
                    </div>
                </div>

                {/* Barra de Busca com Ícone e Botões - Estilo Atualizado */}
                <div className="flex items-center relative">
                    <Input
                        type="search"
                        placeholder="Pesquisar..."
                        className="w-full pl-10 bg-input text-foreground"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground" />
                    <Button
                        variant="outline"
                        size="icon"
                        onClick={toggleFilters}
                        className="ml-2"
                    >
                        <Filter className="h-5 w-5" />
                    </Button>
                    {purchaseMode === "cart" && (
                        <div className="relative ml-2">
                            <div className="relative">
                                <Button
                                    variant="outline"
                                    size="icon"
                                    className="relative bg-secondary text-secondary-foreground hover:bg-accent hover:text-accent-foreground p-1.5"
                                    onClick={() =>
                                        setCurrentPage("finalizar-compra")
                                    }
                                >
                                    <ShoppingCart className="h-5 w-5" />
                                    {cart.length > 0 && (
                                        <span className="absolute -top-2 -right-2 bg-destructive text-destructive-foreground text-xs rounded-full h-5 w-5 flex items-center justify-center">
                                            {cart.length}
                                        </span>
                                    )}
                                </Button>
                            </div>
                        </div>
                    )}
                </div>

                {/* Filtros */}
                {showFilters && (
                    <div className="mt-2 space-y-2 px-6">
                        {filters.map((filter, index) => (
                            <div
                                key={index}
                                className="flex items-center space-x-2"
                            >
                                <select
                                    value={filter.type}
                                    onChange={(e) =>
                                        handleFilterTypeChange(
                                            index,
                                            e.target.value,
                                        )
                                    }
                                    className="rounded-md border bg-background px-3 py-2 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring"
                                >
                                    <option value="brand">Bandeira</option>
                                    <option value="expiry">Validade</option>
                                    <option value="bin">BIN</option>
                                    <option value="bank">Banco</option>
                                    <option value="level">Nível</option>
                                </select>
                                <Input
                                    type="text"
                                    placeholder={
                                        filter.type === "brand"
                                            ? "Ex: Visa"
                                            : filter.type === "expiry"
                                              ? "Ex: 12/25"
                                              : filter.type === "bin"
                                                ? "Ex: 411111"
                                                : filter.type === "bank"
                                                  ? "Ex: NU PAGAMENTOS SA"
                                                  : filter.type === "level"
                                                    ? "Ex: GOLD"
                                                    : "Pesquisar..."
                                    }
                                    value={filter.value}
                                    onChange={(e) =>
                                        handleFilterValueChange(
                                            index,
                                            e.target.value,
                                        )
                                    }
                                    className="flex-grow"
                                />
                                <Button
                                    variant="outline"
                                    size="icon"
                                    onClick={() => removeFilter(index)}
                                    className="ml-2"
                                >
                                    <X className="h-4 w-4" />
                                </Button>
                                {index === filters.length - 1 && (
                                    <Button
                                        variant="outline"
                                        size="icon"
                                        onClick={addFilter}
                                        className="ml-2"
                                    >
                                        <Plus className="h-4 w-4" />
                                    </Button>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {/* Base Switcher Modal */}
            {showSwitcher && (
                <div className="fixed inset-0 flex items-center justify-center z-[9999] isolate">
                    <div
                        className="fixed inset-0 bg-black/80 backdrop-blur"
                        onClick={() => setShowSwitcher(false)}
                    />
                    <div className="relative bg-card rounded-xl p-6 w-96 border shadow-lg">
                        <h3 className="text-xl font-bold text-card-foreground mb-4">
                            Alternar Base
                        </h3>

                        <div className="space-y-3">
                            {["Base 1", "Base 2", "Base 3"].map((base) => {
                                const baseId =
                                    base === "Base 1"
                                        ? "cards"
                                        : base === "Base 2"
                                          ? "cards2"
                                          : "cards3";
                                return (
                                    <button
                                        key={base}
                                        onClick={() => {
                                            switchBase(baseId);
                                            setShowSwitcher(false);
                                        }}
                                        className={`w-full p-4 rounded-lg flex items-center justify-between ${
                                            activeBase === baseId
                                                ? "bg-primary text-primary-foreground"
                                                : "bg-secondary text-secondary-foreground hover:bg-accent hover:text-accent-foreground"
                                        } transition-colors`}
                                    >
                                        <div className="flex items-center gap-3">
                                            <Database className="w-5 h-5" />
                                            <span className="font-medium">
                                                {base}
                                            </span>
                                        </div>
                                        {activeBase === baseId && (
                                            <span className="text-sm bg-accent/50 px-2 py-1 rounded">
                                                Ativa
                                            </span>
                                        )}
                                    </button>
                                );
                            })}
                        </div>

                        <div className="mt-4 pt-4 border-t">
                            <div className="grid grid-cols-3 gap-3">
                                <div className="bg-secondary p-3 rounded-lg">
                                    <span className="text-sm text-muted-foreground">
                                        Base 1
                                    </span>
                                    <p className="text-secondary-foreground font-bold">
                                        {baseStats?.base1?.count} cartões
                                    </p>
                                </div>
                                <div className="bg-secondary p-3 rounded-lg">
                                    <span className="text-sm text-muted-foreground">
                                        Base 2
                                    </span>
                                    <p className="text-secondary-foreground font-bold">
                                        {baseStats?.base2?.count} cartões
                                    </p>
                                </div>
                                <div className="bg-secondary p-3 rounded-lg">
                                    <span className="text-sm text-muted-foreground">
                                        Base 3
                                    </span>
                                    <p className="text-secondary-foreground font-bold">
                                        {baseStats?.base3?.count} cartões
                                    </p>
                                </div>
                            </div>
                        </div>

                        <button
                            onClick={() => setShowSwitcher(false)}
                            className="mt-4 w-full py-2 bg-secondary text-secondary-foreground rounded-lg hover:bg-accent hover:text-accent-foreground transition-colors"
                        >
                            Fechar
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default AdvancedSearch;
