import React, { useState, useEffect, forwardRef } from "react";
import {
    User,
    Share2,
    Loader2,
    LayoutGrid,
    List,
    MapPin,
    UserCircle,
    Hash,
    ShoppingCart,
    X,
    DollarSign,
    IdCard,
    Search,
} from "lucide-react";
import clsx from "clsx";

const Button = forwardRef(
    ({ className, variant = "default", size = "default", ...props }, ref) => {
        const classes = clsx(
            "inline-flex items-center justify-center rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
            {
                "bg-primary text-primary-foreground hover:bg-primary/90":
                    variant === "default",
                "bg-destructive text-destructive-foreground hover:bg-destructive/90":
                    variant === "destructive",
                "border border-input bg-background hover:bg-accent hover:text-accent-foreground":
                    variant === "outline",
                "bg-secondary text-secondary-foreground hover:bg-secondary/80":
                    variant === "secondary",
                "hover:bg-accent hover:text-accent-foreground":
                    variant === "ghost",
                "text-primary underline-offset-4 hover:underline":
                    variant === "link",
            },
            {
                "h-10 px-4 py-2 text-sm": size === "default",
                "h-9 rounded-md px-3 text-sm": size === "sm",
                "h-11 rounded-md px-6 text-base": size === "lg",
                "h-10 w-10": size === "icon",
            },
            className,
        );
        return <button className={classes} ref={ref} {...props} />;
    },
);
Button.displayName = "Button";

const Card = forwardRef(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={clsx(
            "rounded-xl border bg-card text-card-foreground shadow p-6",
            className,
        )}
        {...props}
    />
));
Card.displayName = "Card";

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;
    return (
        <div
            className="fixed inset-0 bg-black/50 flex items-start sm:items-center justify-center z-50 p-4 overflow-y-auto"
            onClick={onClose}
        >
            <div
                className="relative w-full max-w-lg min-h-[200px] bg-card text-card-foreground rounded-xl p-6 my-8 sm:my-0"
                onClick={(e) => e.stopPropagation()}
            >
                <button
                    onClick={onClose}
                    className="absolute right-4 top-4 text-muted-foreground hover:text-foreground transition-colors"
                >
                    <X className="h-5 w-5" />
                </button>
                {children}
            </div>
        </div>
    );
};

function formatTipo(t) {
    if (t === "mix") return "RG e CNH";
    if (t === "rg") return "RG";
    if (t === "cnh") return "CNH";
    return t;
}

const DocRow = ({ doc, openModal, onShare, onBuy }) => (
    <tr
        className="border-t border-border hover:bg-accent/50 transition-colors"
        onClick={() => openModal(doc)}
    >
        <td className="p-4 break-words max-w-[200px]">{doc.nome}</td>
        <td className="p-4">
            <code className="font-mono text-xs bg-secondary px-1.5 py-0.5 rounded">
                {doc.cpf}
            </code>
        </td>
        <td className="p-4">{doc.idade}</td>
        <td className="p-4">{doc.sexo}</td>
        <td className="p-4">{formatTipo(doc.tipo)}</td>
        <td className="p-4 break-words max-w-[200px]">{doc.localizacao}</td>
        <td className="p-4">R$ 10,00</td>
        <td className="p-4">
            <div className="flex gap-2">
                <Button
                    variant="ghost"
                    size="sm"
                    onClick={(e) => {
                        e.stopPropagation();
                        onShare(doc);
                    }}
                    className="h-8 w-8 rounded-full"
                >
                    <Share2 className="h-4 w-4" />
                </Button>
                <Button
                    variant="ghost"
                    size="sm"
                    onClick={(e) => {
                        e.stopPropagation();
                        onBuy(doc);
                    }}
                    className="h-8 w-8 rounded-full"
                >
                    <ShoppingCart className="h-4 w-4" />
                </Button>
            </div>
        </td>
    </tr>
);

const DocTableView = ({ docs, onShare, openModal, onBuy }) => (
    <div className="w-full overflow-x-auto rounded-xl border border-border">
        <table className="w-full border-collapse text-sm">
            <thead>
                <tr className="bg-secondary/50">
                    <th className="p-4 text-left font-medium">Nome</th>
                    <th className="p-4 text-left font-medium">CPF</th>
                    <th className="p-4 text-left font-medium">Idade</th>
                    <th className="p-4 text-left font-medium">Sexo</th>
                    <th className="p-4 text-left font-medium">Tipo</th>
                    <th className="p-4 text-left font-medium">Localização</th>
                    <th className="p-4 text-left font-medium">Preço</th>
                    <th className="p-4 text-left font-medium">Ações</th>
                </tr>
            </thead>
            <tbody>
                {docs.map((doc) => (
                    <DocRow
                        key={doc._id}
                        doc={doc}
                        openModal={openModal}
                        onShare={onShare}
                        onBuy={onBuy}
                    />
                ))}
            </tbody>
        </table>
    </div>
);

const DocDetails = ({ doc }) => (
    <div className="space-y-3 bg-secondary/50 rounded-lg p-4">
        <div className="flex items-start gap-2">
            <User className="h-4 w-4 shrink-0 text-muted-foreground mt-1" />
            <div className="space-y-1">
                <span className="text-sm text-muted-foreground">Nome</span>
                <p className="break-words">{doc.nome}</p>
            </div>
        </div>
        <div className="flex items-center gap-2">
            <Hash className="h-4 w-4 shrink-0 text-muted-foreground" />
            <div className="space-y-1">
                <span className="text-sm text-muted-foreground">CPF</span>
                <p>
                    <code className="font-mono text-xs bg-background px-1.5 py-0.5 rounded">
                        {doc.cpf}
                    </code>
                </p>
            </div>
        </div>
        <div className="flex items-center gap-2">
            <UserCircle className="h-4 w-4 shrink-0 text-muted-foreground" />
            <div className="space-y-1">
                <span className="text-sm text-muted-foreground">
                    Informações
                </span>
                <p>
                    {doc.idade} anos | {doc.sexo}
                </p>
            </div>
        </div>
        <div className="flex items-center gap-2">
            <IdCard className="h-4 w-4 shrink-0 text-muted-foreground" />
            <div className="space-y-1">
                <span className="text-sm text-muted-foreground">Tipo</span>
                <p>{formatTipo(doc.tipo)}</p>
            </div>
        </div>
        <div className="flex items-start gap-2">
            <MapPin className="h-4 w-4 shrink-0 text-muted-foreground mt-1" />
            <div className="space-y-1">
                <span className="text-sm text-muted-foreground">
                    Localização
                </span>
                <p className="break-words">{doc.localizacao}</p>
            </div>
        </div>
    </div>
);

const Documents = (props) => {
    const [visualizationMode, setVisualizationMode] = useState("grid");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [docs, setDocs] = useState([]);
    const [isInfoModalOpen, setInfoModalOpen] = useState(false);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [purchaseModalOpen, setPurchaseModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage] = useState(50);
    const [totalDocs, setTotalDocs] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const token = localStorage.getItem("token");

    async function fetchDocs(page = 1, search = "") {
        try {
            setLoading(true);
            const url = new URL("https://api.astrashopbot.cc/api/get_docs");
            url.searchParams.set("page", page);
            url.searchParams.set("per_page", perPage);
            if (search.trim() !== "")
                url.searchParams.set("search", search.trim());
            const response = await fetch(url.toString(), {
                headers: { "Content-Type": "application/json" },
            });
            const data = await response.json();
            if (data.error) {
                setError(data.error);
                setDocs([]);
                setTotalDocs(0);
            } else {
                setDocs(data.docs || []);
                setTotalDocs(data.total_docs || 0);
                setError(null);
            }
        } catch {
            setError("Erro ao carregar documentos");
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchDocs(currentPage, searchQuery);
    }, [currentPage, searchQuery]); // eslint-disable-line

    const shareDocLink = (doc) => {
        console.log("Sharing doc:", doc);
    };
    const openModal = (doc) => {
        setSelectedDoc(doc);
        setInfoModalOpen(true);
    };
    const handleBuy = (doc) => {
        setSelectedDoc(doc);
        setPurchaseModalOpen(true);
    };
    const handleConfirmPurchase = async () => {
        try {
            const response = await fetch(
                "https://api.astrashopbot.cc/api/purchase_docs",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ docs: [selectedDoc._id] }),
                },
            );
            const data = await response.json();
            if (data.error) alert(data.error);
            else {
                if (data.new_balance !== undefined) {
                    props.setUserBalance(data.new_balance);
                    localStorage.setItem(
                        "userBalance",
                        data.new_balance.toString(),
                    );
                }
                alert("Compra realizada com sucesso!");
                fetchDocs(currentPage, searchQuery);
            }
        } catch {
            alert("Erro ao realizar compra");
        } finally {
            setPurchaseModalOpen(false);
            setSelectedDoc(null);
        }
    };
    const goToPreviousPage = () => {
        if (currentPage > 1) setCurrentPage((p) => p - 1);
    };
    const goToNextPage = () => {
        const totalPages = Math.ceil(totalDocs / perPage);
        if (currentPage < totalPages) setCurrentPage((p) => p + 1);
    };
    const totalPages = Math.ceil(totalDocs / perPage);

    return (
        <div className="min-h-screen p-4 sm:p-6 md:p-8 bg-background">
            <div className="max-w-7xl mx-auto space-y-8">
                <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
                    <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold tracking-tight">
                        Documentos
                    </h1>
                    <div className="flex items-center gap-2">
                        <Button
                            variant={
                                visualizationMode === "grid"
                                    ? "default"
                                    : "ghost"
                            }
                            size="icon"
                            onClick={() => setVisualizationMode("grid")}
                            className="rounded-full"
                        >
                            <LayoutGrid className="h-5 w-5" />
                            <span className="sr-only">Grade</span>
                        </Button>
                        <Button
                            variant={
                                visualizationMode === "table"
                                    ? "default"
                                    : "ghost"
                            }
                            size="icon"
                            onClick={() => setVisualizationMode("table")}
                            className="rounded-full"
                        >
                            <List className="h-5 w-5" />
                            <span className="sr-only">Tabela</span>
                        </Button>
                    </div>
                </div>

                <div className="relative">
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                            setCurrentPage(1);
                        }}
                        placeholder="Pesquisar por nome, idade, sexo ou localização..."
                        className="w-full h-10 pl-10 pr-4 rounded-md bg-input text-foreground text-sm focus:ring-2 focus:ring-primary focus:outline-none"
                    />
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground" />
                </div>

                {loading && (
                    <div className="flex justify-center items-center py-12">
                        <Loader2 className="h-8 w-8 animate-spin" />
                    </div>
                )}
                {error && (
                    <div className="text-center py-12 text-destructive">
                        {error}
                    </div>
                )}

                {!loading && !error && docs.length > 0 && (
                    <>
                        {visualizationMode === "table" ? (
                            <DocTableView
                                docs={docs}
                                onShare={shareDocLink}
                                openModal={openModal}
                                onBuy={handleBuy}
                            />
                        ) : (
                            <div className="grid gap-6 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
                                {docs.map((doc) => (
                                    <Card
                                        key={doc._id}
                                        onClick={() => openModal(doc)}
                                        className="cursor-pointer transition-all duration-200 hover:shadow-lg"
                                    >
                                        <div className="flex flex-col h-full">
                                            <div className="flex items-center justify-between mb-6">
                                                <div className="flex items-center gap-2">
                                                    <User className="h-4 w-4 shrink-0 text-muted-foreground" />
                                                    <span className="text-lg font-semibold break-words">
                                                        {doc.nome}
                                                    </span>
                                                </div>
                                                <div className="flex items-center text-sm font-medium">
                                                    <DollarSign className="h-4 w-4 mr-1 shrink-0" />
                                                    <span>R$ 10,00</span>
                                                </div>
                                            </div>
                                            <div className="flex flex-col space-y-4 flex-1">
                                                <div className="flex items-center gap-2">
                                                    <Hash className="h-4 w-4 shrink-0 text-muted-foreground" />
                                                    <code className="font-mono text-xs bg-secondary px-1.5 py-0.5 rounded">
                                                        {doc.cpf}
                                                    </code>
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    <UserCircle className="h-4 w-4 shrink-0 text-muted-foreground" />
                                                    <span>
                                                        {doc.idade} anos |{" "}
                                                        {doc.sexo}
                                                    </span>
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    <IdCard className="h-4 w-4 shrink-0 text-muted-foreground" />
                                                    <span>
                                                        {formatTipo(doc.tipo)}
                                                    </span>
                                                </div>
                                                <div className="flex items-start gap-2">
                                                    <MapPin className="h-4 w-4 shrink-0 text-muted-foreground mt-1" />
                                                    <span className="break-words">
                                                        {doc.localizacao}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="flex justify-end items-center gap-2 mt-6">
                                                <Button
                                                    variant="secondary"
                                                    size="sm"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        shareDocLink(doc);
                                                    }}
                                                    className="gap-2"
                                                >
                                                    <Share2 className="h-4 w-4" />
                                                    Compartilhar
                                                </Button>
                                                <Button
                                                    variant="default"
                                                    size="sm"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleBuy(doc);
                                                    }}
                                                    className="gap-2"
                                                >
                                                    <ShoppingCart className="h-4 w-4" />
                                                    Comprar
                                                </Button>
                                            </div>
                                        </div>
                                    </Card>
                                ))}
                            </div>
                        )}
                        <div className="flex justify-center items-center gap-4">
                            <Button
                                variant="outline"
                                onClick={goToPreviousPage}
                                disabled={currentPage <= 1}
                            >
                                Anterior
                            </Button>
                            <span className="text-sm">
                                Página {currentPage} de{" "}
                                {Math.max(totalPages, 1)}
                            </span>
                            <Button
                                variant="outline"
                                onClick={goToNextPage}
                                disabled={currentPage >= totalPages}
                            >
                                Próxima
                            </Button>
                        </div>
                    </>
                )}
                {!loading && !error && docs.length === 0 && (
                    <div className="text-center py-12 text-muted-foreground">
                        Nenhum documento encontrado.
                    </div>
                )}

                <Modal
                    isOpen={isInfoModalOpen}
                    onClose={() => setInfoModalOpen(false)}
                >
                    <div className="space-y-6">
                        <h2 className="text-xl font-semibold leading-none tracking-tight">
                            Detalhes do Documento
                        </h2>
                        {selectedDoc && <DocDetails doc={selectedDoc} />}
                    </div>
                </Modal>

                <Modal
                    isOpen={purchaseModalOpen}
                    onClose={() => setPurchaseModalOpen(false)}
                >
                    <div className="space-y-6">
                        <h2 className="text-xl font-semibold leading-none tracking-tight">
                            Confirmar Compra
                        </h2>
                        {selectedDoc && (
                            <div className="space-y-4">
                                <p>Você está prestes a comprar:</p>
                                <DocDetails doc={selectedDoc} />
                                <div className="flex items-center gap-2 pt-2 border-t border-border">
                                    <DollarSign className="h-4 w-4 shrink-0 text-muted-foreground" />
                                    <div className="space-y-1">
                                        <span className="text-sm text-muted-foreground">
                                            Valor Total
                                        </span>
                                        <p className="text-lg font-semibold">
                                            R$ 10,00
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-end gap-2">
                                    <Button
                                        variant="outline"
                                        onClick={() =>
                                            setPurchaseModalOpen(false)
                                        }
                                    >
                                        Cancelar
                                    </Button>
                                    <Button
                                        variant="default"
                                        onClick={handleConfirmPurchase}
                                    >
                                        Confirmar Compra
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default Documents;
