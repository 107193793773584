import React, { useState, useEffect } from "react";
import { LayoutGrid, List, Check } from "lucide-react";
import { motion } from "framer-motion";
import axios from "axios";
import { toast } from "react-toastify";

const API_BASE_URL = "https://api.astrashopbot.cc";

function VisualizationModeButton({ icon, label, isSelected, onClick, isLoading }) {
  return (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={onClick}
      className={`flex flex-col items-center justify-center p-4 rounded-lg transition-colors h-28 relative ${
        isSelected
          ? "bg-blue-600 text-white"
          : "bg-gray-700 text-gray-300 hover:bg-gray-600"
      }`}
      disabled={isLoading}
    >
      {isLoading ? (
        <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-b-2 border-current" />
      ) : (
        <>
          {icon}
          {isSelected && (
            <Check className="absolute top-2 right-2 w-4 h-4 text-white" />
          )}
        </>
      )}
      <span className="mt-2 text-sm text-center">{label}</span>
    </motion.button>
  );
}

export default function VisualizationSettings() {
  const [visualizationMode, setVisualizationMode] = useState(null);
  const [updatingMode, setUpdatingMode] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchVisualizationMode = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/user-settings`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      setVisualizationMode(response.data.settings.visualizationMode || "card");
    } catch (error) {
      console.error("Erro ao buscar modo de visualização:", error);
      toast.error("Erro ao carregar configurações de visualização");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVisualizationMode();
  }, []);

  const handleVisualizationModeChange = async (value) => {
    setUpdatingMode(value);
    try {
      await axios.post(
        `${API_BASE_URL}/api/user-settings`,
        {
          settings: {
            visualizationMode: value
          }
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      );
      // Após atualizar, busca o valor atual do backend
      await fetchVisualizationMode();
    } catch (error) {
      console.error("Erro ao atualizar o modo de visualização:", error);
      toast.error("Erro ao atualizar o modo de visualização");
      // Em caso de erro, recarrega o valor atual do backend
      await fetchVisualizationMode();
    } finally {
      setUpdatingMode(null);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center p-4">
        <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-current"></div>
      </div>
    );
  }

  return (
    <div>
      <h2 className="text-lg font-medium mb-3">Modo de Visualização</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <VisualizationModeButton
          icon={<LayoutGrid className="w-8 h-8" />}
          label="Visualização em Cards"
          isSelected={visualizationMode === "card"}
          onClick={() => handleVisualizationModeChange("card")}
          isLoading={updatingMode === "card"}
        />
        <VisualizationModeButton
          icon={<List className="w-8 h-8" />}
          label="Visualização em Lista"
          isSelected={visualizationMode === "table"}
          onClick={() => handleVisualizationModeChange("table")}
          isLoading={updatingMode === "table"}
        />
      </div>
    </div>
  );
}
