import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "./axiosConfig";
import {
    User,
    Lock,
    LogIn,
    UserPlus,
    CheckCircle,
    XCircle,
    Eye,
    EyeOff,
} from "lucide-react";
import ReCAPTCHA from "react-google-recaptcha";
import { motion, AnimatePresence } from "framer-motion";

const RECAPTCHA_SITE_KEY = "6LeAuGIqAAAAAF8fJyFuoTvtqpKxIULOjw_KnXgR";

// Componentes auxiliares

const Button = ({
    children,
    type = "button",
    onClick,
    className = "",
    disabled,
    icon: Icon,
}) => (
    <button
        type={type}
        onClick={onClick}
        disabled={disabled}
        className={`flex items-center justify-center gap-2 px-4 py-2 font-semibold text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring ${className}`}
    >
        {Icon && <Icon className="w-5 h-5" />}
        {children}
    </button>
);

const Input = ({
    id,
    name,
    type = "text",
    className = "",
    placeholder,
    value,
    onChange,
}) => (
    <input
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={`w-full px-4 py-2 border rounded-lg bg-gray-700 text-white border-gray-600 focus:outline-none focus:border-blue-500 ${className}`}
    />
);

const Card = ({ children, className = "" }) => (
    <div className={`bg-gray-800 rounded-lg shadow-xl ${className}`}>
        {children}
    </div>
);

const CardHeader = ({ children }) => (
    <div className="p-4 border-b border-gray-700">{children}</div>
);

const CardContent = ({ children }) => <div className="p-4">{children}</div>;

const CardFooter = ({ children }) => (
    <div className="p-4 border-t border-gray-700">{children}</div>
);

// Componente principal

export default function AstraShopLogin({ onLoginSuccess }) {
    const [formData, setFormData] = useState({ username: "", password: "" });
    const [csrfToken, setCsrfToken] = useState("");
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isRegistering, setIsRegistering] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const [showPassword, setShowPassword] = useState(false);

    const recaptchaRef = useRef(null);

    useEffect(() => {
        (async () => {
            try {
                const response = await axiosInstance.get("/csrf-token");
                setCsrfToken(response.data.csrf_token);
            } catch {}
        })();
    }, []);

    const handleInputChange = (e) =>
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));

    const togglePasswordVisibility = () => setShowPassword(!showPassword);
    const handleRecaptchaChange = (token) => setRecaptchaToken(token);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        setSuccessMessage("");
        setIsLoading(true);

        if (!formData.username.trim() || !formData.password.trim()) {
            setError("Usuário e senha são obrigatórios.");
            setIsLoading(false);
            return;
        }
        if (!recaptchaToken) {
            setError("Por favor, verifique que você não é um robô.");
            setIsLoading(false);
            return;
        }

        try {
            const endpoint = isRegistering ? "register" : "login";
            const response = await axiosInstance.post(
                `/${endpoint}`,
                { ...formData, recaptcha_token: recaptchaToken },
                {
                    headers: { "X-CSRFToken": csrfToken },
                },
            );
            if (isRegistering) {
                setSuccessMessage("Registro bem-sucedido!");
                setIsRegistering(false);
                setFormData({ username: "", password: "" });
            } else if (response.data.token) {
                localStorage.setItem("token", response.data.token);
                onLoginSuccess();
            } else {
                setError("Erro inesperado. Por favor, tente novamente.");
            }
        } catch (err) {
            if (err.response) {
                const { status, data } = err.response;
                setError(
                    data?.error ||
                        (status === 401
                            ? "Credenciais inválidas."
                            : status === 400
                              ? "Requisição inválida."
                              : "Erro ao processar a solicitação. Tente novamente."),
                );
            } else {
                setError("Ocorreu um erro. Tente novamente.");
            }
        } finally {
            setIsLoading(false);
            recaptchaRef.current && recaptchaRef.current.reset();
            setRecaptchaToken(null);
        }
    };

    const handleToggle = () => {
        setIsRegistering(!isRegistering);
        setError("");
        setSuccessMessage("");
        setFormData({ username: "", password: "" });
        setShowPassword(false);
        recaptchaRef.current && recaptchaRef.current.reset();
        setRecaptchaToken(null);
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-900">
            <Card className="w-full max-w-md p-8 space-y-8 bg-gray-800 rounded-lg shadow-xl">
                <CardHeader>
                    <div className="text-center">
                        <h2 className="mt-6 text-3xl font-bold text-white">
                            AstraShop
                        </h2>
                        <h3 className="mt-2 text-xl text-gray-300">
                            {isRegistering
                                ? "Crie sua conta"
                                : "Bem-vindo ao AstraShop"}
                        </h3>
                    </div>
                </CardHeader>
                <CardContent>
                    <AnimatePresence mode="wait">
                        <motion.div
                            key={isRegistering ? "register" : "login"}
                            initial={{
                                opacity: 0,
                                x: isRegistering ? 50 : -50,
                            }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: isRegistering ? -50 : 50 }}
                            transition={{ duration: 0.5 }}
                        >
                            {successMessage && (
                                <div className="flex justify-center mb-4">
                                    <span className="flex items-center bg-green-500 text-white text-sm font-medium px-4 py-2 rounded-full">
                                        <CheckCircle className="w-4 h-4 mr-2" />
                                        {successMessage}
                                    </span>
                                </div>
                            )}

                            {error && (
                                <div className="flex justify-center mb-4">
                                    <span className="flex items-center bg-red-500 text-white text-sm font-medium px-4 py-2 rounded-full">
                                        <XCircle className="w-4 h-4 mr-2" />
                                        {error}
                                    </span>
                                </div>
                            )}

                            <form onSubmit={handleSubmit} className="space-y-4">
                                <div className="space-y-4">
                                    <div className="relative">
                                        <User className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
                                        <Input
                                            id="username"
                                            name="username"
                                            placeholder="Digite seu usuário"
                                            value={formData.username}
                                            onChange={handleInputChange}
                                            required
                                            className="pl-10"
                                        />
                                    </div>
                                    <div className="relative">
                                        <Lock className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
                                        <Input
                                            id="password"
                                            name="password"
                                            placeholder="Digite sua senha"
                                            value={formData.password}
                                            onChange={handleInputChange}
                                            required
                                            type={
                                                showPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            className="pl-10 pr-12"
                                        />
                                        <button
                                            type="button"
                                            onClick={togglePasswordVisibility}
                                            className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-300 focus:outline-none"
                                        >
                                            {showPassword ? (
                                                <EyeOff className="h-5 w-5" />
                                            ) : (
                                                <Eye className="h-5 w-5" />
                                            )}
                                        </button>
                                    </div>
                                </div>

                                <ReCAPTCHA
                                    sitekey={RECAPTCHA_SITE_KEY}
                                    onChange={handleRecaptchaChange}
                                    ref={recaptchaRef}
                                    theme="dark"
                                />

                                <Button
                                    type="submit"
                                    disabled={isLoading}
                                    className="w-full"
                                    icon={isRegistering ? UserPlus : LogIn}
                                >
                                    {isLoading
                                        ? isRegistering
                                            ? "Registrando..."
                                            : "Entrando..."
                                        : isRegistering
                                          ? "Registrar"
                                          : "Entrar"}
                                </Button>
                            </form>
                        </motion.div>
                    </AnimatePresence>
                </CardContent>
                <CardFooter>
                    <button
                        onClick={handleToggle}
                        className="flex items-center justify-center w-full text-blue-500 hover:text-blue-600 transition-colors duration-200 mt-4"
                    >
                        {isRegistering ? (
                            <>
                                <LogIn className="w-5 h-5 mr-2" />
                                Já é membro? Faça login
                            </>
                        ) : (
                            <>
                                <UserPlus className="w-5 h-5 mr-2" />
                                Novo por aqui? Crie sua conta
                            </>
                        )}
                    </button>
                    <p className="mt-8 text-center text-sm text-gray-400">
                        ©2024 AstraShop. Todos os direitos reservados.
                    </p>
                </CardFooter>
            </Card>
        </div>
    );
}
